import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { sqSystemApi } from '@/sdk/api/SystemApi';
import { sqUserGroupsApi } from '@/sdk/api/UserGroupsApi';
import { convertDuration } from '@/datetime/dateTime.utilities';
import { PriorityV1 } from '@/sdk/model/PriorityV1';
import { Timezone } from '@/datetime/timezone.service';
import { errorToast } from '@/utilities/toast.utilities';
import { setSystemMessage, setSystemWarnings } from '@/systemWarning/systemWarning.actions';
import { sqTimezones } from '@/utilities/datetime.constants';
import { FrontendDuration } from '@/services/systemConfiguration.types';
import { ServerSpecOutputV1, ServerStatusOutputV1, SupportedUnitsOutputV1 } from '@/sdk/model/models';
import { EVERYONE_USERGROUP } from '@/administration/administration.constants';
import { navigateToConnectionErrorPage } from '@/main/routing.utilities';

interface ConfigData {
  configurationOptions: Record<string, unknown>;
  timeZone?: Timezone;
  defaultMaxInterpolation?: FrontendDuration;
  serverSpecs?: Array<ServerSpecOutputV1>;
  defaultMaxCapsuleDuration?: FrontendDuration;
  atUserLimit?: boolean;
  priorities?: PriorityV1[];
  isDataLabAvailable?: boolean;
  seeqGptEnabled?: boolean;
}

let configurationData: ConfigData | undefined;
type SupportedUnitsPromise = Promise<{ [key: string]: string[] } | undefined>;
let supportedUnits: SupportedUnitsPromise;
let everyoneGroupEnabled: boolean | undefined = true;

export function fetchConfiguration() {
  return Promise.resolve(sqSystemApi.getServerStatus({ includeInternal: false }))
    .then(({ data }: AxiosResponse<ServerStatusOutputV1>) => {
      const updatedConfigurationOptions = _.transform(
        data.configurationOptions ?? [],
        (memo: Record<string, any>, { path, value }) => {
          memo[path as string] = value;
        },
        {},
      );
      const offset = data.timeZone?.split(' ')?.[1];

      configurationData = {
        ...data,
        configurationOptions: updatedConfigurationOptions,
        defaultMaxCapsuleDuration: convertDuration(data.defaultMaxCapsuleDuration),
        defaultMaxInterpolation: convertDuration(data.defaultMaxInterpolation),
        timeZone: sqTimezones.findMostCommonTimeZone(offset),
      };
      setSystemWarnings(data.serverSpecs);
      setSystemMessage(systemMessage());
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
      navigateToConnectionErrorPage();
    });
}

export function isConfigurationLoaded() {
  try {
    return configurationData !== undefined;
  } catch {
    return false;
  }
}

function getConfigurationOption(path: string): any {
  if (!_.has(serverConfiguration().configurationOptions, path)) {
    return undefined;
  }

  return serverConfiguration().configurationOptions[path];
}

// Because we need the user groups API, this has to be done after login
export function fetchEveryoneDisabled() {
  return sqUserGroupsApi.getUserGroups({ nameSearch: EVERYONE_USERGROUP }).then(({ data }) => {
    const everyoneGroup = _.find(data.items, { name: EVERYONE_USERGROUP });
    everyoneGroupEnabled = !_.isUndefined(everyoneGroup) && everyoneGroup.isEnabled;
  });
}

/**
 * All the getter methods in this file should return data that is immutable, because these outputs are often used as
 * inputs to useEffect dependency arrays, or passed as props into a React component
 */

export function newHeaderExperienceEnabled() {
  return getConfigurationOption('Features/UX/NewHeader/Enabled');
}

export function assetGroupEditorEnabled() {
  return getConfigurationOption('Features/AssetGroupEditor/Enabled');
}

export function seeqDirectoryEnabled() {
  return getConfigurationOption('Authentication/SeeqDirectory/Enabled');
}

export function getPasswordMinLength() {
  return getConfigurationOption('Authentication/SeeqDirectory/PasswordMinLength');
}

export function getPasswordUppercaseRequired() {
  return getConfigurationOption('Authentication/SeeqDirectory/PasswordComplexity/RequireUppercase');
}

export function getPasswordLowercaseRequired() {
  return getConfigurationOption('Authentication/SeeqDirectory/PasswordComplexity/RequireLowercase');
}

export function getPasswordDigitRequired() {
  return getConfigurationOption('Authentication/SeeqDirectory/PasswordComplexity/RequireDigit');
}

export function getPasswordSpecialCharacterRequired() {
  return getConfigurationOption('Authentication/SeeqDirectory/PasswordComplexity/RequireSpecialCharacter');
}

// Memoized since it is not expected to change, but only needed in some tools
export function getSupportedUnits(): SupportedUnitsPromise {
  if (_.isUndefined(supportedUnits)) {
    supportedUnits = sqSystemApi.getSupportedUnits().then(({ data }: { data: SupportedUnitsOutputV1 }) => data.units);
  }

  return supportedUnits;
}

function serverConfiguration(): Readonly<ConfigData> {
  if (_.isUndefined(configurationData)) {
    throw new Error('Configuration must be fetched before being accessed');
  }

  return configurationData;
}

export function defaultMaxInterpolation(): FrontendDuration | undefined {
  return serverConfiguration().defaultMaxInterpolation;
}

export function priorityColors(): PriorityV1[] | undefined {
  return serverConfiguration().priorities;
}

export function serverTimezone(): Timezone | undefined {
  return configurationData?.timeZone;
}

export function isDataLabAvailable() {
  return configurationData?.isDataLabAvailable;
}

export function seeqVersion(): string | undefined {
  return getConfigurationOption('Installation/Version');
}

export function seeqReleaseIdentifier(): number | undefined {
  const releaseIdentifier = getConfigurationOption('Installation/ReleaseIdentifier');
  const releaseIdentifierMatch = releaseIdentifier?.match(/^(\d{4})-(\d{2})-(\d{2})/);
  if (releaseIdentifierMatch) {
    return _.toNumber(`${releaseIdentifierMatch[1]}${releaseIdentifierMatch[2]}${releaseIdentifierMatch[3]}`);
  }
}

export function seeqFullReleaseIdentifier(): string | undefined {
  return getConfigurationOption('Installation/ReleaseIdentifier');
}

export function maxScatterPlotSamples() {
  return getConfigurationOption('Features/ScatterPlot/MaxPoints');
}

export function serverSpecs() {
  return serverConfiguration().serverSpecs;
}

export function restrictLogs() {
  return getConfigurationOption('Features/RestrictLogs/Enabled');
}

export function registrationEnabled() {
  return getConfigurationOption('Features/UserRegistration/Enabled');
}

export function authDefaultProviderId() {
  return getConfigurationOption('Authentication/DefaultProviderId');
}

export function authAutoLogin() {
  return getConfigurationOption('Authentication/AutoLogin');
}

export function isTelemetryEnabled() {
  return getConfigurationOption('Features/Telemetry/Enabled');
}

export function isTelemetryAnonymized() {
  return getConfigurationOption('Features/Telemetry/Anonymized');
}

export function adminContactName(): string {
  return getConfigurationOption('Installation/AdminContact/Name');
}

export function adminContactEmail(): string {
  return getConfigurationOption('Installation/AdminContact/Email');
}

export function defaultNumberFormat() {
  return getConfigurationOption('Features/NumberFormat');
}

export function defaultStringFormat() {
  return getConfigurationOption('Features/StringFormat');
}

export function addOnToolsEnabled() {
  return getConfigurationOption('Features/AddOnTools/Enabled');
}

export function systemMessage() {
  return getConfigurationOption('Features/Messages/SystemMessage');
}

export function auditingEnabled() {
  return getConfigurationOption('Features/Auditing/Enabled');
}

export function auditingAllUsersCanRead() {
  return getConfigurationOption('Features/Auditing/AllUsersCanRead');
}

export function dayFirstDefault() {
  return getConfigurationOption('Features/Datafiles/DayFirstDefault') as boolean;
}

export function displaysEnabled() {
  return getConfigurationOption('Features/Displays/Enabled');
}

export function delimiterDefault() {
  return getConfigurationOption('Features/Datafiles/DelimiterDefault').toLowerCase();
}

export function officeHoursEnabled() {
  return getConfigurationOption('Features/HomeScreen/OfficeHours/Enabled');
}

export function customSidebar() {
  return getConfigurationOption('Features/HomeScreen/CustomSidebar');
}

export function pluginsEnabled() {
  return getConfigurationOption('Features/Plugins/Enabled');
}

export function releaseNotesEnabled() {
  return getConfigurationOption('Features/ReleaseNotes/Enabled');
}

export function hardwareWarningsEnabled() {
  return getConfigurationOption('Features/HardwareWarnings/Enabled');
}

export function aiAssistantEnabled() {
  return configurationData?.seeqGptEnabled;
}

export function genAIEnabled() {
  return getConfigurationOption('Features/GenAI/Enabled');
}

export function genAIAgents(): string {
  return getConfigurationOption('Features/GenAI/Agents');
}

export function isLicenseWarningHidden() {
  return getConfigurationOption('Features/LicenseWarning/Hidden');
}

export function isEveryoneGroupEnabled() {
  return everyoneGroupEnabled;
}

export function isAdvancedDateRangeSwapEnabled() {
  return getConfigurationOption('Features/AdvancedDateRangeSwap/Enabled');
}

export function isTableDefinitionEditorEnabled() {
  return getConfigurationOption('Features/TableDefinitionEditor/Enabled');
}

export function atUserLimit() {
  return configurationData?.atUserLimit || false;
}

export function isSeeqPreviewEnabled() {
  return getConfigurationOption('Features/SeeqPreview/Enabled');
}

export function isMixpanelEnabled() {
  return getConfigurationOption('Features/Mixpanel/Enabled');
}

export function isAnnouncementsEnabled() {
  return getConfigurationOption('Features/Announcements/Enabled');
}

export function isLockingEnabled() {
  return getConfigurationOption('Features/Workbook/Locking/Enabled');
}

export function isVantageChartsEnabled() {
  return getConfigurationOption('Features/Vantage/Charts/Enabled');
}

export function isVantageNameBasedAssetContextEnabled() {
  return getConfigurationOption('Features/Vantage/NameBasedAssetContext/Enabled');
}

export function isVersioningEnabled() {
  return getConfigurationOption('Features/Versioning/Enabled');
}

export function getDefaultMaxInterpolation(): FrontendDuration | undefined {
  return serverConfiguration().defaultMaxInterpolation;
}

export function getDefaultMaxCapsuleDuration(): FrontendDuration | undefined {
  return serverConfiguration().defaultMaxCapsuleDuration;
}

export function isConditionMonitorNotificationsEnabled(): boolean {
  return getConfigurationOption('Features/Notifications/ConditionMonitors/Enabled');
}

export function isEmailerServiceEnabled(): boolean {
  return getConfigurationOption('Features/EmailerService/Enabled');
}

export function getConditionMonitorSchedule(): string {
  return getConfigurationOption('Features/Notifications/ConditionMonitors/Schedule');
}

export function getConditionMonitorScheduleDescription(): string {
  return getConfigurationOption('Features/Notifications/ConditionMonitors/ScheduleDescription');
}

export function getConditionMonitorMinimumInterval(): number {
  return getConfigurationOption('Features/Notifications/ConditionMonitors/MinimumInterval');
}

export function getItemFinderSchedule(): string {
  return getConfigurationOption('Features/ItemFinders/Schedule');
}

export function getItemFinderScheduleDescription(): string {
  return getConfigurationOption('Features/ItemFinders/ScheduleDescription');
}

export function getLoginMessage(): string {
  return getConfigurationOption('Features/Login/InfoMessage');
}

export function vantageEnabled() {
  return getConfigurationOption('Features/Vantage/Enabled');
}

export function isConditionTableChartsEnabled() {
  return getConfigurationOption('Features/ConditionTableCharts/Enabled');
}

// only used for testing
export function resetConfigurationData() {
  configurationData = undefined;
}

export function getODataDefaultProtocol() {
  return getConfigurationOption('Features/OData/DefaultProtocol');
}

export function isODataLegacyEnabled() {
  return getConfigurationOption('Features/OData/Legacy/Enabled');
}

export function isSelfServicePasswordResetEnabled() {
  return getConfigurationOption('Authentication/SeeqDirectory/SelfServicePasswordReset');
}

export function isImpactReportsToolEnabled() {
  return getConfigurationOption('Features/ImpactReports/Enabled');
}

export function isEnforcePasswordAgeEnabled() {
  return getConfigurationOption('Authentication/SeeqDirectory/EnforcePasswordAge');
}

export function getMaxPasswordAge() {
  return getConfigurationOption('Authentication/SeeqDirectory/MaxPasswordAge');
}

export function getPasswordExpirationGracePeriod() {
  return getConfigurationOption('Authentication/SeeqDirectory/PasswordExpirationGracePeriod');
}

export function getPasswordExpirationWarningPeriod() {
  return getConfigurationOption('Authentication/SeeqDirectory/PasswordExpirationWarningPeriod');
}

export function isSelfOrganizingMapsToolEnabled() {
  return getConfigurationOption('Features/MLTools/SelfOrganizingMaps/Enabled');
}

export function isIsolationForestToolEnabled() {
  return getConfigurationOption('Features/MLTools/IsolationForest/Enabled');
}

export function isPartialLeastSquaresToolEnabled() {
  return getConfigurationOption('Features/MLTools/PartialLeastSquares/Enabled');
}
