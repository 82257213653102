// @ts-strict-ignore
import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import tinygradient from 'tinygradient';
import i18next from 'i18next';
import { emitPermissions } from '@/services/notifier.service';
import { addTable, fetchAllFftTables, fetchAllTables, fetchTableData } from '@/trendData/trendTable.actions';
import { getCapsuleFormula, inflateTimes } from '@/datetime/dateTime.utilities';
import { removeGaps, updateLaneDisplay } from '@/trendData/yAxis.actions';
import { addRecentlyAccessed } from '@/workbook/workbook.actions';
import {
  findChildrenIn,
  findItemIn,
  getAllChildItems,
  getAllItems,
  getTrendStores,
} from '@/trend/trendDataHelper.utilities';
import { fetchMinimapSignals, fetchPlot, refreshScatterPlotView } from '@/scatterPlot/scatterPlot.actions';
import { ItemOutputV1, ThresholdMetricOutputV1 } from '@/sdk/model/models';
import { ProcessTypeEnum } from '@/sdk/model/ThresholdMetricOutputV1';
import { ErrorTypeEnum } from '@/sdk/model/FormulaErrorOutputV1';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { CapsuleFormulaTableRow, SPIKECATCHER_PER_PIXEL, TableSortParams } from '@/utilities/formula.constants';
import { API_TYPES, NUMBER_CONVERSIONS, STRING_UOM } from '@/main/app.constants';
import { ThresholdOutputV1 } from '@/sdk/model/ThresholdOutputV1';
import { SummaryTypeEnum } from '@/sdk/model/ContentInputV1';
import { addMetricColumn, fetchTable } from '@/tableBuilder/tableBuilder.actions';
import { TableColumnOutputV1 } from '@/sdk/model/TableColumnOutputV1';
import { sqAnnotationsApi } from '@/sdk/api/AnnotationsApi';
import { sqMetricsApi } from '@/sdk/api/MetricsApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { logError, logWarn } from '@/utilities/logger';
import { formatMessage } from '@/utilities/logger.utilities';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { formatNumber, FormatOptions } from '@/utilities/numberHelper.utilities';
import { cancelAll, cancelGroup, CANCELLATION_GROUP_GUID_SEPARATOR, count } from '@/requests/pendingRequests.utilities';
import { isCanceled } from '@/utilities/http.utilities';
import {
  getMSPerPixelWidth,
  getToolType,
  isApplePlatform,
  isCapsuleFullyVisible,
  isInWorkbookRouteAndWorkbookLoaded,
  isPresentationWorkbookMode,
  isStringSeries,
  isTimestampVisible,
  isTrendable,
} from '@/utilities/utilities';
import {
  API_TYPES_TO_ITEM_TYPES,
  BAR_CHART_LINE_WIDTHS,
  CAPSULE_PANEL_LOOKUP_COLUMNS,
  CAPSULES_PER_PAGE,
  CapsuleTimeColorMode,
  CHART_CAPSULES_LIMIT,
  CHILD_CLONED_PROPERTIES,
  CompareViewColorMode,
  CUSTOMIZATION_MODES,
  CustomLabelTargetUpdate,
  DASH_STYLES,
  FORMULA_FRAGMENT_TYPE,
  ITEM_CHILDREN_TYPES,
  ITEM_DATA_STATUS,
  ITEM_TYPES,
  LABEL_LOCATIONS,
  LaneCustomLabel,
  LINE_WIDTHS,
  PREVIEW_ID,
  PROPERTIES_COLUMN_PREFIX,
  PROPERTIES_UOM_COLUMN_PREFIX,
  PropertyColumn,
  SAMPLE_OPTIONS,
  SERIES_PANEL_EXTRA_TREND_COLUMNS,
  SHADED_AREA_CURSORS,
  SHADED_AREA_DIRECTION,
  SHADED_AREA_TYPES,
  SwapPairs,
  TREND_CAPSULE_INFLATION,
  TREND_COLORS,
  TREND_CONDITION_STATS,
  TREND_PANELS,
  TREND_SIGNAL_STATS,
  TREND_VIEWS,
} from '@/trendData/trendData.constants';
import { errorToast, infoToast, successToast, warnToast } from '@/utilities/toast.utilities';
import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { addCondition, addSignal } from '@/utilities/autoGroup.utilities';
import { flux } from '@/core/flux.module';
import { groupedNonCapsuleSeries, isHidden } from '@/utilities/trendChartItemsHelper.utilities';
import { PUSH_IGNORE, PushOption } from '@/core/flux.service';
import { headlessCaptureMetadata, notifyCancellation } from '@/utilities/screenshot.utilities';
import {
  sqDurationStore,
  sqInvestigateStore,
  sqTrendCapsuleStore,
  sqTrendConditionStore,
  sqTrendMetricStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqWorkbenchStore,
  sqWorkbookStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { ReactSelectOption } from '@/core/IconSelect.molecule';
import { CHART_THRESHOLDS } from '@/trend/trendViewer/trendViewer.constants';
import { WORKSHEET_VIEW } from '@/worksheet/worksheet.constants';
import { doTrack } from '@/track/track.service';
import { FrontendDuration } from '@/services/systemConfiguration.types';
import { computeLightestColor } from '@/core/html.utilities';
import { calculate } from '@/trend/trendViewer/capsuleBuckets.utilities';
import {
  conditionFormula,
  makeCapsulesUnique,
  removeInvalidCapsules,
} from '@/tools/manualCondition/conditionFormula.service';
import { buildSignalSegmentsFormula } from '@/trendData/trendSeries.utilities';
import { fetchTreemap } from '@/treemap/treemap.actions';
import { handleForbidden, isForbidden, isItemRedacted } from '@/utilities/redaction.utilities';
import {
  computeCapsules,
  computeCapsulesWithLimit,
  computeCapsuleTable,
  computeSamples,
  computeScalar,
  computeTable,
  getBuildAdditionalFormula,
  getDependencies,
  getPropertyAndStatisticsColumns,
} from '@/utilities/formula.utilities';
import { fetchItemUsages } from '@/search/search.actions';
import { RangeExport } from '@/trendData/duration.store';
import {
  clearInvestigatedItem,
  closeInvestigationTool,
  updateDerivedDataTree,
} from '@/toolSelection/investigate.actions';
import { HeadlessCategory } from '@/services/headlessCapture.constants';
import { DEBOUNCE } from '@/core/core.constants';
import { tabsetChangeTab } from '@/worksheet/worksheet.actions';
import { AnyProperty, WithProperty } from '@/utilities.types';
import { fetchAnnotations, showEntry } from '@/annotation/annotation.actions';
import { displayRange } from '@/trendData/duration.actions';
import { getMaxSeriesPixels } from '@/core/utilities';
import { CustomPropertyValuesAndUOMs } from '@/trendData/trendData.types';
import { triggerManualConditionPreviewDebounced } from '@/tools/manualCondition/manualCondition.actions';
import { fetchMonitoredConditionIds } from '@/notifications/notifications.actions';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { GraphQLInputV1, sqGraphQLApi } from '@/sdk';
import { isBackendRowsLimitError } from '@/trendData/trend.utilities';
import { setTrendColorMode } from '@/trendData/trendColor.actions';

let chartWidth = process.env.NODE_ENV === 'test' ? 1 : computeChartWidth(window.innerWidth);
let debouncedSetCapsuleTimeOffsets;
let debouncedFetchAllTimeseries;
const DESCRIPTION = 'description';
const DATASOURCE_NAME = 'datasourceName';
const PIXELS_PER_BREAK = 0;
const previewSeriesCancellationGroup = 'previewChartSeries';
const previewCapsulesCancellationGroup = 'previewChartCapsules';
const fetchTableDebounced = _.debounce(() => fetchTable(), DEBOUNCE.SHORT);
const fetchConditionsDebounced = _.debounce(() => {
  exposedForTesting.fetchAllTimebarCapsules();
  exposedForTesting.fetchTableAndChartCapsules();
  fetchPlot();
}, DEBOUNCE.SHORT);
const fetchHiddenDebounced = _.debounce(() => exposedForTesting.fetchHiddenTrendData(), DEBOUNCE.SHORT);
const fetchCapsuleTimeDebounced = _.debounce(() => {
  exposedForTesting.addCapsuleTimeSegments(true);
  exposedForTesting.createStitchDetails();
}, DEBOUNCE.SHORT);
const fetchAllStatisticsDebounced = _.debounce(() => exposedForTesting.fetchAllStatistics(true), DEBOUNCE.SHORT);

const debouncedStatisticsFetchers: Record<string, (item: any) => void> = {};
function fetchStatisticsDebounced(item: any) {
  if (!debouncedStatisticsFetchers[item.id]) {
    debouncedStatisticsFetchers[item.id] = _.debounce((item: any) => {
      exposedForTesting.fetchStatistics(item);
      delete debouncedStatisticsFetchers[item.id];
    }, DEBOUNCE.SHORT);
  }
  debouncedStatisticsFetchers[item.id](item);
}

const GET_CONTEXT_CONDITIONS_QUERY = `
  query Context($ids: [ContextIdInput!]!) {
    context(ids: $ids) {
      conditions {
        guid
        sourceItemId
      }
    }
  }
  `;
type ContextConditionsMap = Record<string, string>;

/**
 * Enables an "editing mode" for capsule sets. The id specified is the id of the capsule set the
 * user is editing. To enable preview the existing results belonging to that id will be removed from
 * the display and replaced with the new, temporary preview result.
 *
 * @param capsuleSetId - the id of the capsule set that is being edited.
 */
export function setEditModeForCapsuleSet(capsuleSetId: string | number) {
  const oldEditingId = sqTrendSeriesStore.editingId;
  flux.dispatch('TREND_SET_EDITING_CAPSULE_SET_ID', { id: capsuleSetId });
  if (capsuleSetId !== oldEditingId) {
    exposedForTesting.fetchTableAndChartCapsules();
  }
}

/**
 * Enables an "editing mode" for calculated series. The id specified is the id of the series the
 * user is editing. To enable preview the existing results belonging to that id will be removed from
 * the display and replaced with the new, temporary preview result.
 *
 * @param seriesId - the id of the calculated series that is being edited.
 */
export function setEditModeForSeries(seriesId: string | number) {
  flux.dispatch('TREND_SET_EDITING_SERIES_ID', { id: seriesId });
}

/**
 * Generates capsules for preview purposes.
 *
 * @param formula - the formula to use to generate the capsules
 * @param parameters - the parameters to use to resolve the formula.
 * @param capsuleSetId - the id of the capsule set or undefined if creating a new search
 * @param color - the color to display the resulting capsules in formatted in hex code (e.g. #CCCCCC)
 * @param [usePost=false] - if true make the request with POST /formula/run instead of GET /formula/run
 *
 * @returns {Promise} that resolves once the results have been added to the chart
 */
export function generatePreviewCapsules(
  formula: string,
  parameters: any,
  capsuleSetId: string,
  color: string,
  usePost = false,
) {
  const capsuleSet = sqTrendConditionStore.findItem(capsuleSetId) ?? sqTrendCapsuleStore.previewChartItem;

  return cancelGroup(previewCapsulesCancellationGroup)
    .then(() =>
      computeCapsules({
        formula,
        parameters,
        range: sqDurationStore.displayRange,
        cancellationGroup: previewCapsulesCancellationGroup,
        usePost,
      }),
    )
    .then((result) => {
      flux.dispatch('TREND_SET_CHART_CAPSULES_PREVIEW', {
        formula,
        parameters,
        capsules: result,
        id: capsuleSetId,
        existingCapsuleSet: capsuleSet,
        color,
        lane: capsuleSet?.lane ?? sqTrendStore.nextLane,
      });
    })
    .catch(() => {
      exposedForTesting.displayEmptyPreviewCapsuleLane();
    });
}

/**
 * Cancels the preview capsules
 */
export function cancelPreviewCapsules() {
  return cancelGroup(previewCapsulesCancellationGroup);
}

/**
 * Generate preview condition from passed capsules
 *
 * @param capsuleSetId - Id for the set of capsules.
 * @param capsules - List capsules to preview.
 * @param color - Color of capsule preview e.g #eb4034.
 */
export const generatePreviewConditionFromCapsules = (
  capsuleSetId: string,
  capsules: {
    id?: string;
    startTime: number;
    endTime: number;
  }[],
  color: string,
) => {
  if (capsules.length === 0) {
    removeCapsuleSetFromPreviewLane(capsuleSetId);
    const capsuleSet = sqTrendConditionStore.findItem(capsuleSetId) ?? sqTrendCapsuleStore.previewChartItem;
    if (_.isEmpty(capsuleSet)) {
      displayEmptyPreviewCapsuleLane(); // Note: the empty lane isn't shown until it succeeds once
    }

    return Promise.resolve();
  }

  return Promise.resolve(capsules)
    .then((capsules) =>
      _.map(capsules, ({ startTime, endTime }) => ({
        startTime,
        endTime,
        properties: [],
      })),
    )
    .then(removeInvalidCapsules)
    .then(makeCapsulesUnique)
    .then(conditionFormula)
    .then((formula) =>
      generatePreviewCapsules(
        formula,
        {},
        capsuleSetId,
        color,
        true, // usePost - Formulas can become large enough to exceed node's max http header size
      ),
    );
};

/**
 * Generates series for preview purposes.
 *
 * @param formula - the formula to use to generate the series
 * @param {object} parameters - the parameters to use to resolve the formula.
 * @param seriesId - the id of the series or undefined if creating a new search
 * @param color - the color to display the resulting capsules in formatted in hex code (e.g. #CCCCCC)
 * @returns {Promise} that resolves once the results have been added to the chart
 */
export function generatePreviewSeries(formula: string, parameters, seriesId: string, color: string): Promise<any> {
  let series = sqTrendSeriesStore.findItem(seriesId);
  const numPixels = Math.min(chartWidth, getMaxSeriesPixels());

  // If loading for the first time we won't have a chartWidth, so no need to fetch data that will be overwritten as
  // soon as the chart is instantiated.
  if (!_.isNumber(chartWidth)) {
    return Promise.resolve();
  }

  if (_.isUndefined(series) && _.startsWith(seriesId, PREVIEW_ID)) {
    series = sqTrendSeriesStore.previewChartItem;
  }

  const laneWidth = `${getMSPerPixelWidth(sqDurationStore.displayRange.duration.asMilliseconds(), numPixels)}ms`;
  const downSampleFormula = sqTrendStore.buildDownsampleFormula(laneWidth, seriesId);
  return cancelGroup(previewSeriesCancellationGroup)
    .then(() =>
      computeSamples({
        formula: `${formula}${sqTrendStore.buildSummarizeFormula(seriesId)}${downSampleFormula}`,
        parameters,
        range: sqDurationStore.displayRange,
        limit: numPixels * SPIKECATCHER_PER_PIXEL,
        cancellationGroup: previewSeriesCancellationGroup,
      }),
    )
    .then((result) => {
      flux.dispatch('TREND_SET_CHART_SERIES_PREVIEW', {
        formula,
        parameters,
        id: seriesId,
        color,
        samples: result.samples,
        valueUnitOfMeasure: result.valueUnitOfMeasure,
        interpolationMethod: result.interpolationMethod,
        lane: series && series.lane ? series.lane : sqTrendStore.nextLane,
        alignment: series && series.axisAlign ? series.axisAlign : sqTrendStore.nextAlignment,
      });
    })
    .catch((e) => {
      const id = _.get(series, 'id', PREVIEW_ID);
      flux.dispatch('TREND_SET_CHART_SERIES_PREVIEW', {});
      exposedForTesting.catchItemDataFailure(id, previewSeriesCancellationGroup, e);
    });
}

/**
 * Dispatches the TREND_SET_SUMMARY call to the store, updating the dataSummary in the store with the
 * given payload
 * @param summary - The new summary values
 * @param refetch - Trigger refetch time series or not
 */
export function setTrendSummary(
  summary: {
    type: SummaryTypeEnum;
    value: number;
    isSlider: boolean;
    discreteUnits: FrontendDuration;
  },
  refetch = true,
) {
  flux.dispatch('TREND_SET_SUMMARY', summary);

  if (refetch) {
    if (sqTrendStore.view === TREND_VIEWS.CAPSULE) {
      exposedForTesting.addCapsuleTimeSegments(true);
    } else {
      debouncedFetchAllTimeseries = lazyDebounceOf(exposedForTesting.fetchAllTimeseries, debouncedFetchAllTimeseries);
      debouncedFetchAllTimeseries();
    }
  }
}

/**
 * Sets the status for all non-redacted items displayed in the detailsPanel to "not required". This is useful when
 * requests are forcefully cancelled (without the user requesting the cancellation explicitly) and avoiding issues
 * with the loading indicator.
 */
export function setItemStatusNotRequired() {
  _.forEach(
    getAllItems({
      excludeDataStatus: [ITEM_DATA_STATUS.REDACTED],
    }),
    (item) => {
      flux.dispatch('TREND_SET_DATA_STATUS_NOT_REQUIRED', { id: item.id }, PUSH_IGNORE);
    },
  );
}

/**
 * Cancels the preview series
 */
export function cancelPreviewSeries() {
  return cancelGroup(previewSeriesCancellationGroup);
}

/**
 * Removes the preview Capsules from the trend.
 */
export function removePreviewCapsules() {
  flux.dispatch('TREND_REMOVE_CHART_CAPSULES_PREVIEW');
}

/**
 * Removes the preview Series from the trend.
 */
export function removePreviewSeries() {
  flux.dispatch('TREND_REMOVE_CHART_SERIES_PREVIEW');
}

/**
 * Displays an empty preview lane. Opposed to removePreviewCapsules it does not reset
 * the previous stored capsules if available.
 */
export function displayEmptyPreviewCapsuleLane() {
  flux.dispatch('TREND_DISPLAY_EMPTY_CAPSULE_PREVIEW');
}

/**
 * Removes a capsule set lane from preview. It is used in removing preview capsule sets in situations whereby there
 * are multiple preview lanes belonging to different capsule sets.
 */
export function removeCapsuleSetFromPreviewLane(capsuleSetId) {
  flux.dispatch('TREND_REMOVE_CAPSULE_SET_FROM_PREVIEW_LANE', { capsuleSetId });
}

/**
 * Sets the chart configuration mode
 */
export function setCustomizationMode(customizationMode) {
  flux.dispatch('TREND_SET_CUSTOMIZATION_MODE', { customizationMode });
}

/**
 * Sets the visibility of the capsule set names displayed in the capsule lane.
 *
 * @param show - Whether or not to display capsule names
 */
export function setCapsuleLaneLabels(show: boolean) {
  flux.dispatch('TREND_SET_SHOW_CAPSULE_LABELS', { showCapsuleLabels: show });
}

/**
 * Toggles the visibility of the signal names and units of measure displayed in the signal lane.
 *
 * @param property - The property to be set, must be one of LABEL_PROPERTIES
 * @param value - The value to be set, must be one of LABEL_LOCATIONS
 */
export function setLabelDisplayConfiguration(property: string, value: string) {
  const payload = {
    property,
    value,
  };
  flux.dispatch('TREND_SET_LABEL_DISPLAY_CONFIG', payload);
}

/**
 * Sets the visibility of the gridlines on the chart.
 * @param showGridlines - true if we want to turn on gridlines, false if we want to turn off gridlines
 * @param skipWarning - (optional, defaults to false) true if we want to skip the "Gridlines can not be shown..."
 *  warning (i.e. if the caller has already shown the warning)
 */
export function setGridlines(showGridlines: boolean, skipWarning = false) {
  const payload = { showGridlines, skipWarning };
  flux.dispatch('TREND_SET_GRIDLINES', payload);
}

export function setIsCompareMode(isCompareMode) {
  flux.dispatch('TREND_SET_IS_COMPARE_MODE', { isCompareMode });
}

/**
 * Sets the sort order of a column in a panel.
 *
 * @param panel - The name of the panel. Must be one of TREND_PANELS
 * @param sortBy - The name of the column by which to sort
 * @param sortAsc - True to sort ascending, false otherwise
 * @param option - One of the WORKSTEP_PUSH constants
 *
 * @throws TypeError if the panel is not recognized.
 */
export function setPanelSort(panel: TREND_PANELS, sortBy: string, sortAsc: boolean, option?: PushOption) {
  if (!_.includes(_.values(TREND_PANELS), panel)) {
    throw new TypeError(`${panel} is not a valid panel`);
  }

  flux.dispatch('TREND_SET_PANEL_SORT', { panel, sortBy, sortAsc }, option);

  if (panel === TREND_PANELS.CAPSULES) {
    exposedForTesting.setCapsulePanelOffset(0);
  }
}

/**
 * Toggles the sort order of a column in a panel. If switching sort to a new column it defaults to sorting in
 * ascending order.
 *
 * @param panel - The name of the panel. Must be one of TREND_PANELS
 * @param sortBy - The name of the column by which to sort
 */
export function togglePanelSort(panel: TREND_PANELS, sortBy: string) {
  if (!_.includes(_.values(TREND_PANELS), panel)) {
    throw new TypeError(`${panel} is not a valid panel`);
  }

  const currentSort = sqTrendStore.getPanelSort(panel);
  const sortAsc = currentSort.sortBy === sortBy ? !currentSort.sortAsc : true;
  exposedForTesting.setPanelSort(panel, sortBy, sortAsc);
}

/**
 * Sets one or more properties to be applied to a panel
 *
 * @param panel - The name of the panel. Must be one of TREND_PANELS
 * @param props - Keys and values to apply to the panel
 */
export function setPanelProps(panel: string, props: object) {
  if (!_.includes(_.values(TREND_PANELS), panel)) {
    throw new TypeError(`${panel} is not a valid panel`);
  }

  flux.dispatch('TREND_SET_PANEL_PROPS', { panel, props });
  if (panel === TREND_PANELS.BOTTOM) {
    setChartWidth();
  }
}

/**
 * Sets the offsets for capsule time
 *
 * @param lower - The duration of the lower offset
 * @param upper - The duration of the upper offset
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 */
export function setTrendCapsuleTimeOffsets(lower: number, upper: number, option?) {
  flux.dispatch('TREND_SET_CAPSULE_TIME_OFFSETS', { lower, upper }, option);

  debouncedSetCapsuleTimeOffsets = lazyDebounceOf(onSetCapsuleTimeOffsets, debouncedSetCapsuleTimeOffsets);
  debouncedSetCapsuleTimeOffsets();

  function onSetCapsuleTimeOffsets() {
    exposedForTesting.addCapsuleTimeSegments(true);
  }
}

/**
 * Resets the capsule time offsets to their defaults, as though the user has not changed the x-axis.
 */
export function resetTrendCapsuleTimeOffsets() {
  exposedForTesting.setTrendCapsuleTimeOffsets(0, 0);
}

/**
 * Sets the selected region on the chart and updates statistics since they are tied to the selected region.
 *
 * @param min - A timestamp for the minimum side of the range.
 * @param max - A timestamp for the maximum side of the range.
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 */
export function setTrendSelectedRegion(min: number, max: number, option?) {
  flux.dispatch('TREND_SET_SELECTED_REGION', { min, max }, option);
  exposedForTesting.fetchAllStatistics(sqTrendStore.view !== TREND_VIEWS.CAPSULE);
}

/**
 * Removes the selected region.
 *
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 */
export function removeTrendSelectedRegion(option?) {
  exposedForTesting.setTrendSelectedRegion(0, 0, option);
}

/**
 * Register options to display when selected region plus icon is clicked
 *
 * @param identifier - The plugin identifier.
 * @param options - The options to display on selected region plus icon.
 */
export function addTrendSelectedRegionOptions(identifier: string, options: string[]) {
  flux.dispatch('TREND_ADD_SELECTED_REGION_OPTIONS', { [identifier]: options });
}

/**
 * Register options to display when selected region plus icon is clicked
 *
 * @param identifier - The plugin identifier.
 * @param options - The options to display on selected region plus icon.
 */
export function removeTrendSelectedRegionOptions() {
  flux.dispatch('TREND_REMOVE_SELECTED_REGION_OPTIONS', {});
}

/**
 * Zooms to the selected region.
 *
 * @param [keepRegion=false] - If true, does not remove the selected region
 */
export function zoomTrendToSelectedRegion(keepRegion = false) {
  if (!sqTrendStore.isRegionSelected) {
    return;
  }

  const min = sqTrendStore.selectedRegion.min;
  const max = sqTrendStore.selectedRegion.max;
  if (sqTrendStore.view === TREND_VIEWS.CAPSULE) {
    exposedForTesting.setTrendCapsuleTimeOffsets(0 + min, max - sqTrendSeriesStore.longestCapsuleSeriesDuration);
  } else {
    displayRange.updateTimes(min, max);
  }

  if (!keepRegion) {
    exposedForTesting.removeTrendSelectedRegion();
  }
}

/**
 * Zooms out to the selected capsules, or to include all capsules if none are selected.
 */
export function zoomOutToCapsules() {
  const selectedCapsules = _.filter(sqTrendCapsuleStore.items, 'selected');
  const capsules = selectedCapsules.length ? selectedCapsules : sqTrendCapsuleStore.items;
  if (capsules.length) {
    const minTime = (_.chain(capsules).map('startTime') as any).min().value();
    const maxTime = (_.chain(capsules).map('endTime') as any).max().value();
    const inflation = inflateTimes(minTime, maxTime, TREND_CAPSULE_INFLATION);
    displayRange.updateTimes(inflation.start, inflation.end);
  }
}

export function updateCapsuleGrouping() {
  if (sqTrendStore.isTrendViewCapsuleTime()) {
    exposedForTesting.addCapsuleTimeSegments();
  } else if (sqTrendStore.isTrendViewChainView()) {
    exposedForTesting.createStitchDetails();
  }
}

/**
 * Changes the view mode. If moving to a new view it prepares for the new chart.  In capsule time it then converts
 * all capsules into timeseries items, because capsule time is a chart displaying the timeseries for a capsule's
 * start and end times.
 *
 * @param view - Set the new view: TREND_VIEWS.CALENDAR, TREND_VIEWS.CHAIN, or TREND_VIEWS.CAPSULE
 */
export function setTrendView(view: string) {
  const previousView = sqTrendStore.view;
  const requestsToCancel =
    previousView === TREND_VIEWS.CAPSULE ? sqTrendSeriesStore.capsuleSeries : sqTrendSeriesStore.nonCapsuleSeries;

  _.forEach(requestsToCancel, (series: any) => {
    flux.dispatch('TREND_SET_DATA_STATUS_NOT_REQUIRED', { id: series.id }, PUSH_IGNORE);
    return cancelGroup(`fetchTimeseries${series.id}`, true);
  });

  flux.dispatch('TREND_SET_VIEW', { view });

  if (view === TREND_VIEWS.CAPSULE) {
    exposedForTesting.addCapsuleTimeSegments(true);
  } else if (view === TREND_VIEWS.CHAIN) {
    flux.dispatch('TREND_RECOMPUTE_CHART_CAPSULES', null, PUSH_IGNORE);

    let capsulePromise;
    // re-fetch the chart capsules if we're coming from capsule time as those could have changed (CRAB-20414)
    if (previousView === TREND_VIEWS.CAPSULE) {
      capsulePromise = exposedForTesting.fetchChartCapsules();
    } else {
      capsulePromise = Promise.resolve();
    }

    capsulePromise.then(exposedForTesting.createStitchDetails);
  } else {
    exposedForTesting.fetchAllTimeseries();
    exposedForTesting.fetchChartCapsules();
  }

  // Remove the selected region when navigating to or away from capsule view
  if (
    (previousView !== TREND_VIEWS.CAPSULE && view === TREND_VIEWS.CAPSULE) ||
    (previousView === TREND_VIEWS.CAPSULE && view !== TREND_VIEWS.CAPSULE)
  ) {
    exposedForTesting.removeTrendSelectedRegion();
  }

  // The behavior of fetchTableAndChartCapsules changes slightly in dimming mode
  if (
    (previousView !== TREND_VIEWS.CALENDAR && view === TREND_VIEWS.CALENDAR) ||
    (previousView === TREND_VIEWS.CALENDAR && view !== TREND_VIEWS.CALENDAR)
  ) {
    // This mirrors the logic in `fetchTableAndChartCapsules` for getting the conditions to fetch
    if (
      _.chain(
        getAllItems({
          excludeDataStatus: [ITEM_DATA_STATUS.REDACTED, ITEM_DATA_STATUS.FAILURE, ITEM_DATA_STATUS.CANCELED],
          workingSelection: true,
          excludeEditingCondition: true,
          itemTypes: [ITEM_TYPES.CONDITION],
        }),
      )
        .some((item) => isHidden({ item }))
        .value()
    ) {
      // If some items in the working selection are hidden we need to re-fetch the capsule table
      // because the conditionIds that we would use to build the table have changed with the view
      exposedForTesting.fetchTableAndChartCapsules();
    }
  }

  if (view !== TREND_VIEWS.CHAIN) {
    flux.dispatch('TREND_SET_STITCH_BREAKS', { stitchBreaks: [] }, PUSH_IGNORE);
    flux.dispatch('TREND_SET_STITCH_TIMES', { stitchTimes: [] }, PUSH_IGNORE);
  }

  // This is more of a sanity check that all the displayed data is shown; if all the data is already shown this
  // will be a no-op.
  exposedForTesting.fetchHiddenTrendData();
}

const manageSignalSegmentsLoad = ({ interest, capsules, isFetchRequired }) => {
  // We only want to run the code below if the capsule will appear on the trend
  if (isHidden({ item: interest })) {
    return;
  }

  const capsulesToLoad = [];
  _.forEach(capsules, (capsule) => {
    const existingCapsuleSeries: any = _.find(sqTrendSeriesStore.capsuleSeries, {
      capsuleId: capsule.id,
      interestId: interest.id,
    });
    if (existingCapsuleSeries) {
      const { properties } = capsule;
      let compareProperties = {};
      if (!_.isEmpty(properties)) {
        const { column, compareBy } = existingCapsuleSeries;
        compareProperties = {
          column: properties[sqTrendStore.separateByProperty] || column,
          colorBy: properties[sqTrendStore.colorByProperty] || compareBy,
        };
      }
      flux.dispatch(
        'TREND_SET_PROPERTIES',
        {
          id: existingCapsuleSeries.id,
          dirty: false,
          ...compareProperties,
        },
        PUSH_IGNORE,
      );
    } else {
      flux.dispatch('TREND_ADD_SERIES_FROM_CAPSULE', { capsule, interest }, PUSH_IGNORE);
      capsulesToLoad.push(capsule);
    }
  });

  if (isFetchRequired) {
    return exposedForTesting.fetchSignalSegments(interest.id, capsules);
  }

  if (capsulesToLoad.length > 0) {
    return exposedForTesting.fetchSignalSegments(interest.id, capsulesToLoad);
  }
};

/**
 * Adds all capsule time segments for the given time series and makes each series an interest of its respective
 * capsule. Defaults to adding all capsules for all time series. Existing capsule series are simply updated with
 * new data, new ones are added, and no longer displayed ones are removed. Simply removing all the capsule series
 * would cause an undesirable flicker when auto-updated is enabled.
 *
 * @param [isFetchRequired] - True if fetching the data is required for existing capsule series
 * @param {Array<Item>} [seriesToUpdate] - The array of time series for which capsule time segments will be added.
 * If not provided, defaults to all capsules in the capsule store
 * @returns {Promise} Resolves when all timeseries have been fetched
 */
export function addCapsuleTimeSegments(isFetchRequired = false, seriesToUpdate = []) {
  const MAX_CAPSULE_TIME_ITEMS = sqTrendStore.getMaxCapsuleTimeItems();

  if (!sqTrendStore.isTrendViewCapsuleTime() || sqTrendStore.capsulePanelIsLoading) {
    return;
  }

  let itemsOfInterest;
  let existingCapsuleSeries;
  if (_.isEmpty(seriesToUpdate)) {
    itemsOfInterest =
      sqTrendStore.hideUnselectedItems && _.some(sqTrendSeriesStore.nonCapsuleSeries, ['selected', true])
        ? _.filter(sqTrendSeriesStore.nonCapsuleSeries, ['selected', true])
        : sqTrendSeriesStore.nonCapsuleSeries;
    existingCapsuleSeries = sqTrendSeriesStore.capsuleSeries;
  } else {
    itemsOfInterest =
      sqTrendStore.hideUnselectedItems && _.some(seriesToUpdate, ['selected', true])
        ? _.filter(seriesToUpdate, ['selected', true])
        : seriesToUpdate;
    existingCapsuleSeries = _.filter(sqTrendSeriesStore.capsuleSeries, (capsuleSeries) =>
      _.find(itemsOfInterest, { id: capsuleSeries.interestId }),
    );
  }

  _.forEach(existingCapsuleSeries, (capsuleSeries) => {
    flux.dispatch('TREND_SET_PROPERTIES', { id: capsuleSeries.id, dirty: true }, PUSH_IGNORE);
  });

  let allCapsuleSeriesLoads: Promise<any[]>;
  let isCapsuleTimeLimited = false;
  let visibleItemsCount = 0;
  let visibleCapsulesCount = 0;

  // If capsule group mode is enabled then only signals that are paired to a condition are shown during the
  // conditions capsules. This requires filtering of the capsules by condition and then explicit loading of only
  // the grouped signals.
  if (sqWorksheetStore.capsuleGroupMode) {
    let seriesCapsulePairList = _.chain(_.keys(sqWorksheetStore.conditionToSeriesGrouping))
      .flatMap((conditionId) => {
        // find the capsules that belong to a given condition
        const condition = { isChildOf: conditionId, notFullyVisible: false };
        _.assign(
          condition,
          sqTrendStore.hideUnselectedItems && _.some(sqTrendCapsuleStore.items, { selected: true })
            ? { selected: true }
            : {},
        );
        return _.filter(sqTrendCapsuleStore.items, condition);
      })
      .flatMap((capsule: any) =>
        _.chain(itemsOfInterest)
          .filter((series) =>
            // ensure only linked signal's segments are loaded.
            _.includes(sqWorksheetStore.conditionToSeriesGrouping[capsule.isChildOf], series.id),
          )
          .compact()
          .reject('isChildOf')
          .map((interest) => {
            return { interest, capsule };
          })
          .value(),
      )
      .value();

    if (seriesCapsulePairList.length > MAX_CAPSULE_TIME_ITEMS) {
      seriesCapsulePairList = seriesCapsulePairList.slice(0, MAX_CAPSULE_TIME_ITEMS);
      isCapsuleTimeLimited = true;
      visibleItemsCount = _.uniqBy(seriesCapsulePairList, 'interest').length;
      visibleCapsulesCount = _.uniqBy(seriesCapsulePairList, 'capsule').length;
    }
    const capsulesBySeriesId: Record<string, object[]> = _.reduce(
      seriesCapsulePairList,
      (result, value) => {
        const signalId = value.interest.id;
        (result[signalId] || (result[signalId] = [])).push(value.capsule);
        return result;
      },
      {},
    );

    allCapsuleSeriesLoads = _.chain(capsulesBySeriesId)
      .flatMap((capsules, interestId) => {
        const interest = sqTrendSeriesStore.findItem(interestId);

        return manageSignalSegmentsLoad({ interest, capsules, isFetchRequired });
      })
      .thru((promises) => Promise.all(promises))
      .value();
  } else {
    const capsules =
      sqTrendStore.hideUnselectedItems && _.some(sqTrendCapsuleStore.items, ['selected', true])
        ? _.filter(sqTrendCapsuleStore.items, ['selected', true])
        : sqTrendCapsuleStore.items;
    const visibleCapsules = _.reject(capsules, (capsule) => !isTimestampVisible(capsule.startTime));
    const allVisibleItems = _.chain(itemsOfInterest)
      .reject((item) => isHidden({ item }))
      .reject('isChildOf')
      .value();
    // low probability to trend more than MAX_CAPSULE_TIME_ITEMS items, but if this happens, take
    // MAX_CAPSULE_TIME_ITEMS items and show one capsule for each of them
    const displayableItems = allVisibleItems.slice(0, Math.min(MAX_CAPSULE_TIME_ITEMS, allVisibleItems.length));
    const maxSegmentsPerItem = MAX_CAPSULE_TIME_ITEMS / displayableItems.length;

    const segmentsToLoad = visibleCapsules.slice(0, Math.min(maxSegmentsPerItem, visibleCapsules.length));
    allCapsuleSeriesLoads = _.chain(displayableItems)
      .flatMap((interest) => manageSignalSegmentsLoad({ interest, capsules: segmentsToLoad, isFetchRequired }))
      .thru((promises) => Promise.all(promises))
      .value();

    visibleItemsCount = allVisibleItems.length;
    visibleCapsulesCount = visibleCapsules.length;
    isCapsuleTimeLimited = visibleItemsCount * visibleCapsulesCount > MAX_CAPSULE_TIME_ITEMS;
  }

  flux.dispatch(
    'TREND_SET_CAPSULE_TIME_LIMITED',
    {
      enabled: isCapsuleTimeLimited,
      itemsCount: visibleItemsCount,
      capsulesCount: visibleCapsulesCount,
    },
    PUSH_IGNORE,
  );

  return allCapsuleSeriesLoads.finally(() => {
    const seriesFromCapsules = _.chain(sqTrendSeriesStore.capsuleSeries)
      .filter('dirty')
      .map((capsuleSeries) => ({
        capsuleId: capsuleSeries.capsuleId,
        interestId: capsuleSeries.interestId,
      }))
      .value();
    if (!_.isEmpty(seriesFromCapsules)) {
      flux.dispatch('TREND_REMOVE_SERIES_FROM_CAPSULE', { seriesFromCapsules }, PUSH_IGNORE);
    }
    exposedForTesting.setCapsuleTimeColorMode();
    updateLaneDisplay();
  });
}

/**
 * Removes all unselected capsule for each series (also removes each capsule as an interest of the capsule).
 */
export function removeUnselectedSeriesFromCapsules() {
  const removeUnselectedConditions =
    !sqTrendStore.isTrendViewCapsuleTime() || _.some(sqTrendCapsuleStore.items, ['selected', true]);
  const removeUnselectedSeries =
    !sqTrendStore.isTrendViewCapsuleTime() || _.some(sqTrendSeriesStore.nonCapsuleSeries, ['selected', true]);
  const remainingSeriesFromCapsules = [];
  const seriesFromCapsules = [];
  _.forEach(sqTrendCapsuleStore.items, function (capsule) {
    _.forEach(sqTrendSeriesStore.nonCapsuleSeries, function (interest) {
      if ((!capsule.selected && removeUnselectedConditions) || (!interest.selected && removeUnselectedSeries)) {
        seriesFromCapsules.push({
          capsuleId: capsule.id,
          interestId: interest.id,
        });
      } else {
        remainingSeriesFromCapsules.push({
          capsuleId: capsule.id,
          interestId: interest.id,
        });
      }
    });
  });
  if (!_.isEmpty(seriesFromCapsules)) {
    flux.dispatch('TREND_REMOVE_SERIES_FROM_CAPSULE', { seriesFromCapsules });
  }

  const visibleItemsCount = _.uniqBy(remainingSeriesFromCapsules, 'interestId').length;
  const visibleCapsulesCount = _.uniqBy(remainingSeriesFromCapsules, 'capsuleId').length;
  const isCapsuleTimeLimited = visibleItemsCount * visibleCapsulesCount > sqTrendStore.getMaxCapsuleTimeItems();

  flux.dispatch(
    'TREND_SET_CAPSULE_TIME_LIMITED',
    {
      enabled: isCapsuleTimeLimited,
      itemsCount: visibleItemsCount,
      capsulesCount: visibleCapsulesCount,
    },
    PUSH_IGNORE,
  );
}

/**
 * Sets a column to be enabled or disabled in the specified panel
 *
 * @param {TREND_PANELS} panel - The name of the panel. Must be one of TREND_PANELS
 * @param {String|Object} column - The column being toggled. Must be one of TREND_COLUMNS or TREND_SIGNAL_STATS unless
 * it is a custom column definition.
 * @param {Boolean} enabled - True if the column is enabled, false otherwise
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 * @throws TypeError if the column or panel is not recognized.
 */
export function setColumnEnabled(
  panel: TREND_PANELS,
  column: string | AnyProperty,
  enabled: boolean,
  option?: PushOption,
) {
  flux.dispatch(
    'TREND_SET_COLUMN_ENABLED',
    {
      panel,
      column: _.isString(column) ? column : column.key,
      columnDefinition: _.isString(column) ? undefined : column,
      enabled,
    },
    option,
  );

  if (enabled && _.some(TREND_SIGNAL_STATS.concat(TREND_CONDITION_STATS), ['key', column])) {
    exposedForTesting.fetchDataBasedOnView();
    if (!sqTrendStore.isTrendViewCapsuleTime()) {
      exposedForTesting.fetchAllStatistics(true);
    }
  }

  if (
    enabled &&
    (column === DESCRIPTION ||
      column === DATASOURCE_NAME ||
      _.some(sqTrendStore.propertyColumns(TREND_PANELS.SERIES), _.flow(_.property('key'), _.partial(_.eq, column))))
  ) {
    exposedForTesting.fetchPropsForAllItems();
  }

  if (
    enabled &&
    sqTrendStore.customizationMode !== CUSTOMIZATION_MODES.OFF &&
    !_.includes(SERIES_PANEL_EXTRA_TREND_COLUMNS, column)
  ) {
    exposedForTesting.setCustomizationMode(CUSTOMIZATION_MODES.OFF);
  }

  if (enabled && panel === TREND_PANELS.CAPSULES) {
    exposedForTesting.fetchTableAndChartCapsules();
  }

  if (panel === TREND_PANELS.CHART_CAPSULES) {
    flux.dispatch('TREND_RECOMPUTE_CHART_CAPSULES', {}, PUSH_IGNORE);
  }
}

/**
 * Toggles a particular column on or off and then refreshes the statistics.
 * Hides the customize panel if it is showing and the column is being toggled on so the statistics are visible.
 *
 * @param panel - The name of the panel. Must be one of TREND_PANELS
 * @param columnKey - The column being toggled. Must be one of TREND_COLUMNS.
 */
export function toggleTrendColumn(panel: TREND_PANELS, columnKey: string): boolean {
  const shouldEnable = !sqTrendStore.isColumnEnabled(panel, columnKey);
  exposedForTesting.setColumnEnabled(panel, columnKey, shouldEnable);
  if (
    shouldEnable &&
    panel === TREND_PANELS.CHART_CAPSULES &&
    !sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, columnKey)
  ) {
    exposedForTesting.setColumnEnabled(TREND_PANELS.CAPSULES, columnKey, true);
  }
  if (panel !== TREND_PANELS.CHART_CAPSULES) {
    exposedForTesting.removeColumnFilter(columnKey, panel);
  }

  return shouldEnable;
}

/**
 * Toggles a custom statistics column for the capsule panel.
 *
 * @param {Object} statistic - One of the TREND_SIGNAL_STATS
 * @param itemId - id of a series
 */
export function toggleStatisticsColumn(statistic, itemId: string) {
  const column = {
    key: `${statistic.key}.${itemId}`,
    referenceSeries: itemId,
    statisticKey: statistic.key,
  };
  const panel = TREND_PANELS.CAPSULES;
  const wasEnabled = sqTrendStore.isColumnEnabled(panel, column.key);
  exposedForTesting.setColumnEnabled(panel, column, !wasEnabled);
}

/**
 * Adds a property column that retrieves propertyName from the item. Column will be enabled.
 *
 * @param panel - The name of the panel. Must be one of TREND_PANELS
 * @param {Object} propertyInput - object to be used as a column template definition.
 * @param {String} propertyInput.propertyName - the name of the property that will be requested with `getProperty`
 * @param forceEnabled - True to force the column enabled, regardless of its current status
 */
export function addPropertiesColumnToTrend(panel: TREND_PANELS, propertyInput, forceEnabled = false) {
  const property = _.assign(
    {
      key: PROPERTIES_COLUMN_PREFIX + propertyInput.propertyName,
      uomKey: PROPERTIES_UOM_COLUMN_PREFIX + propertyInput.propertyName,
    },
    propertyInput,
  );
  flux.dispatch('TREND_ADD_PROPERTY_COLUMN', { panel, property }, PUSH_IGNORE);
  exposedForTesting.setColumnEnabled(
    panel,
    property.key,
    forceEnabled || !sqTrendStore.isColumnEnabled(panel, property.key),
  );
  if (panel === TREND_PANELS.CHART_CAPSULES && !sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, property.key)) {
    flux.dispatch('TREND_ADD_PROPERTY_COLUMN', { panel: TREND_PANELS.CAPSULES, property }, PUSH_IGNORE);
    exposedForTesting.setColumnEnabled(
      TREND_PANELS.CAPSULES,
      property.key,
      forceEnabled || !sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, property.key),
    );
  }
}

export function addMultiplePropertiesColumn(panel: TREND_PANELS, propertyInputs: any[], forceEnabled = false) {
  const properties = _.map(propertyInputs, (propertyInput) =>
    _.assign(
      {
        key: PROPERTIES_COLUMN_PREFIX + propertyInput.propertyName,
        uomKey: PROPERTIES_UOM_COLUMN_PREFIX + propertyInput.propertyName,
      },
      propertyInput,
    ),
  );
  flux.dispatch('TREND_ADD_MULTIPLE_PROPERTY_COLUMNS', { panel, properties }, PUSH_IGNORE);
  _.forEach(properties, (property) =>
    exposedForTesting.setColumnEnabled(
      panel,
      property.key,
      forceEnabled || !sqTrendStore.isColumnEnabled(panel, property.key),
    ),
  );
}

/**
 * Checks if the column is a property column
 * @param column - the column to be checked
 * @returns true for a property column, false otherwise.
 */
export function isPropertyColumn(column): boolean {
  return !!(column.propertyName && column.key === PROPERTIES_COLUMN_PREFIX + column.propertyName);
}

/**
 * Removes a property from TREND_PANELS.CHART_CAPSULES
 *
 * @param property
 */
export function removeChartCapsulesProperty(property: PropertyColumn) {
  exposedForTesting.setColumnEnabled(TREND_PANELS.CHART_CAPSULES, property.key, false, PUSH_IGNORE);
  flux.dispatch('TREND_REMOVE_PROPERTY_COLUMN', {
    panel: TREND_PANELS.CHART_CAPSULES,
    property,
  });
}

/**
 * Removes a property column
 *
 * @param panel - The name of the panel. Must be one of TREND_PANELS
 * @param {object} property - column definition (created by `addPropertiesColumn`).
 */
export function removePropertiesColumn(panel: TREND_PANELS, property) {
  if (sqTrendStore.isColumnEnabled(panel, property.key)) {
    exposedForTesting.setColumnEnabled(panel, property.key, false, PUSH_IGNORE);
  }

  flux.dispatch('TREND_REMOVE_PROPERTY_COLUMN', { panel, property });
  if (panel === TREND_PANELS.CAPSULES && sqTrendStore.isColumnEnabled(TREND_PANELS.CHART_CAPSULES, property.key)) {
    removeChartCapsulesProperty(property);
  }
  if (panel !== TREND_PANELS.CHART_CAPSULES) {
    exposedForTesting.removeColumnFilter(property.propertyName ?? property.key, panel);
  }
}

/**
 * Sets the color mode for the lines in each lane of capsule time. There are four different modes:
 * - Signal: every line gets its color from its signal, regardless of its condition
 * - Rainbow: every line gets a unique color, except that sort-order acts as a proxy for grouping colors
 * - SignalGradient: the base color is that of the signal and then a gradient is computed from a lighter version
 * of the color. The same grouping rules apply as in Rainbow.
 * - ConditionGradient: the base color is that of the condition and then a gradient is computed from a lighter version
 * of the color. The same grouping rules apply as in Rainbow.
 *
 * @param mode - The color mode
 */
export function setCapsuleTimeColorMode(mode: CapsuleTimeColorMode = sqTrendStore.capsuleTimeColorMode) {
  if (sqTrendStore.view !== TREND_VIEWS.CAPSULE) {
    return;
  }

  const pushMode = mode === sqTrendStore.capsuleTimeColorMode ? PUSH_IGNORE : undefined;
  flux.dispatch('TREND_SET_CAPSULE_TIME_COLOR_MODE', { mode }, pushMode);
  const sortBy = sqTrendStore.getPanelSort(TREND_PANELS.CAPSULES).sortBy;
  const capsules = _.reject(sqTrendCapsuleStore.items, (item) => item.notFullyVisible) as any[];
  const requiredColorsCount = _.uniqBy(capsules, (capsule) => _.get(capsule, sortBy)).length;
  const itemsForGradients =
    {
      [CapsuleTimeColorMode.ConditionGradient]: sqTrendConditionStore.items,
      [CapsuleTimeColorMode.SignalGradient]: sqTrendSeriesStore.nonCapsuleSeries,
    }[mode] || [];
  const colorGradients = _.transform(
    itemsForGradients as any[],
    (memo, item) => {
      memo[item.id] =
        requiredColorsCount < 2
          ? [item.color]
          : _.chain(computeLightestColor(item.color, 0.2))
              .thru((lightestColor) => tinygradient([lightestColor, item.color]).rgb(requiredColorsCount))
              .map((color) => color.toString('rgb'))
              .value();
    },
    {} as { string: string[] },
  );

  let previousGroupByValue;
  const previousColors = {};
  let index = 0;
  _.forEach(capsules, (capsule) => {
    let capsuleValue = _.get(capsule, sortBy);
    if (_.startsWith(sortBy, 'statistics')) {
      capsuleValue = formatNumber(capsuleValue, capsule.formatOptions);
    }

    const isGrouped =
      !_.isUndefined(previousGroupByValue) &&
      previousGroupByValue === capsuleValue &&
      mode !== CapsuleTimeColorMode.Signal;

    _.chain(sqTrendSeriesStore.findChildren(capsule.id))
      .filter({ childType: ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE })
      .forEach((signal) => {
        const [color, childColor] = isGrouped ? previousColors[signal.isChildOf] : computeColors(signal);
        previousColors[signal.isChildOf] = [color, childColor];

        exposedForTesting.setTrendItemColor(signal.id, color, pushMode);
        exposedForTesting.setTrendItemProps(capsule.id, { childColor: childColor || color }, pushMode);
      })
      .value();

    if (!isGrouped) {
      index++;
    }
    previousGroupByValue = capsuleValue;

    function computeColors(signal: any): [string, string?] {
      switch (mode) {
        case CapsuleTimeColorMode.Rainbow:
          return [TREND_COLORS[index % TREND_COLORS.length]];
        case CapsuleTimeColorMode.ConditionGradient: {
          const conditionGradient = colorGradients[capsule.isChildOf];
          return [conditionGradient[index % conditionGradient.length]];
        }
        case CapsuleTimeColorMode.SignalGradient: {
          const signalGradient = colorGradients[signal.isChildOf];
          const color = signalGradient[index % signalGradient.length];
          return [color, tinycolor(color).greyscale().toString('rgb')];
        }
        case CapsuleTimeColorMode.Signal:
          return [sqTrendSeriesStore.findItem(signal.isChildOf).color];
        default:
          throw new TypeError(`Unknown capsule time color mode: ${mode}`);
      }
    }
  });
}

export function setCompareViewColorMode(mode: CompareViewColorMode) {
  flux.dispatch('TREND_SET_COMPARE_VIEW_COLOR_MODE', { mode });
}

/**
 * Dispatches to the store to create, and set, the stitch details
 *
 * @returns {Promise} Resolves when data for stitches is fetched.
 */
export function createStitchDetails() {
  if (sqTrendStore.view !== TREND_VIEWS.CHAIN) {
    return Promise.resolve();
  }

  flux.dispatch('TREND_SET_STITCH_DETAILS', { numPixels: Math.min(chartWidth, getMaxSeriesPixels()) }, PUSH_IGNORE);

  if (sqTrendCapsuleStore.stitchDetailsSet) {
    return exposedForTesting.fetchAllTimeseries();
  } else {
    return Promise.resolve();
  }
}

/**
 * Fetches statistics for all signals and conditions in the details pane.
 *
 * @param fetchSeries - True if the stats for series should be fetched in addition to capsule sets stats
 */
export function fetchAllStatistics(fetchSeries = false) {
  const itemTypes = fetchSeries ? [ITEM_TYPES.SERIES, ITEM_TYPES.CONDITION] : [ITEM_TYPES.CONDITION];
  return _.chain(
    getAllItems({
      itemTypes,
      itemChildrenTypes: [ITEM_CHILDREN_TYPES.METRIC_DISPLAY],
      workingSelection: !!sqTrendStore.hideUnselectedItems,
    }),
  )
    .map(_.unary(exposedForTesting.fetchStatistics))
    .thru((p) => Promise.all(p))
    .value();
}

/**
 * Updates the statistics for conditions or signals. This entails figuring out the correct time range for which to
 * request stats. It is either the selected region, if present, or the display range. A formula is built that
 * returns a statistic that corresponds to each enabled stat column in the details pane.
 *
 * @param {Object} item - The condition or signal
 * @return {Promise} Resolves when all the statistics finish computing
 */
export function fetchStatistics(item) {
  const stats =
    item.itemType === ITEM_TYPES.SCALAR
      ? []
      : _.chain(item.itemType === ITEM_TYPES.SERIES ? TREND_SIGNAL_STATS : TREND_CONDITION_STATS)
          .filter((stat: any) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, stat.key))
          .filter((stat: any) => (isStringSeries(item) ? stat.isStringCompatible : true))
          .value();

  if (
    (item.childType && item.childType !== ITEM_CHILDREN_TYPES.METRIC_DISPLAY) ||
    _.isEmpty(stats) ||
    sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND ||
    isPresentationWorkbookMode()
  ) {
    return Promise.resolve();
  }

  const viewCapsule = getCapsuleFormula(
    sqTrendStore.isRegionSelected
      ? {
          start: sqTrendStore.selectedRegion.min,
          end: sqTrendStore.selectedRegion.max,
        }
      : sqDurationStore.displayRange,
  );
  const enums = _.map(stats, 'stat').join(', ');

  let dispatchItemId = item.id;

  // Compute statistics on the metric's display signal but dispatch the results to the metric item.
  if (item.childType === ITEM_CHILDREN_TYPES.METRIC_DISPLAY) {
    const parentMetric = sqTrendMetricStore.findItem(item.isChildOf);
    const metricProcessType = _.get(parentMetric, 'definition.processType');

    if (metricProcessType && metricProcessType !== ProcessTypeEnum.Simple) {
      dispatchItemId = parentMetric.id;
    } else {
      // We can't compute statistics for simple metrics because display signal is a formula function
      // Reset in case the type has changed
      flux.dispatch('TREND_SET_STATISTICS', { id: parentMetric.id, statistics: {} }, PUSH_IGNORE);
      return Promise.resolve();
    }
  }

  const cancellationGroup = `stats${CANCELLATION_GROUP_GUID_SEPARATOR}${item.id}`;
  const formula = `group(${viewCapsule}).toTable('stats').addStatColumn('series', $series, ${enums})`;
  const dispatchParams = { id: dispatchItemId, statistics: {} };
  // Reset all enabled statistics for this item before requesting new values
  flux.dispatch('TREND_SET_STATISTICS', dispatchParams, PUSH_IGNORE);
  return cancelGroup(cancellationGroup, true)
    .then(() =>
      computeTable({
        formula,
        parameters: { series: getItemId(item) },
        cancellationGroup,
      }).then((table: { data: any[] }) => {
        _.chain(table.data[0]) // Only ever one row of data
          .drop(2) // First two columns are always start and end time
          .forEach((val, i) => _.set(dispatchParams, stats[i].key, val))
          .value();
        flux.dispatch('TREND_SET_STATISTICS', dispatchParams, PUSH_IGNORE);
      }),
    )
    .catch((e) => exposedForTesting.catchItemDataFailure(item.id, cancellationGroup, e));
}

/**
 * Adds an item that comes from the REST API to one of the item stores. Since this is meant to be invoked when the
 * user decides to graph a new item it changes to chain, if the user is in capsule time.
 *
 * This function also fetches statistics (if enabled).
 *
 * @param {Object} item - The item to add with a type property that identifies what type of item it is.
 * @param [props] - additional properties to be set on the item before fetching
 * @param [option] - One of the WORKSTEP_PUSH constants
 * @param skipDependencies - flags whether to skip property dependencies
 * @throws TypeError if the item type is not recognized.
 * @return {Object} A promise that resolves with the item when it is finished fetching.
 */
export function addTrendItem(item, props?: object, option?: string, skipDependencies = false): Promise<any> {
  let promise: Promise<any>;
  let doUpdateLaneDisplay = true;
  const type = getItemType(item) || item.type; // Journals don't have an ITEM_TYPE so we compare with the API_TYPES
  const existingItem = findItemIn(getTrendStores(), item.id);
  // If adding an item that was a child remove it first so it will get a new color and lane
  if (existingItem && existingItem.isChildOf) {
    exposedForTesting.removeItems([existingItem]);
  }

  if (ITEM_TYPES.SERIES === type) {
    promise = addSeries(item, props, option);
  } else if (ITEM_TYPES.SCALAR === type) {
    promise = addScalar(item, props, option);
  } else if (ITEM_TYPES.CONDITION === type) {
    promise = addCapsuleSet(item, props, option);
  } else if (ITEM_TYPES.TABLE === type) {
    doUpdateLaneDisplay = false;
    promise = addTable(item, props, option);
  } else if (ITEM_TYPES.METRIC === type) {
    promise = addMetric(item, props, option);
  } else if (API_TYPES.JOURNAL === type) {
    return addAnnotation(item);
  } else {
    throw new TypeError(`item type "${type}" is not trendable`);
  }

  if (doUpdateLaneDisplay) {
    // Called before data is fetched so that the existing items 'move' out of the way (CRAB-8386).
    updateLaneDisplay();
  }

  // Wait for promise to resolve because we need calculationType to be set on the item
  return promise.then(() => {
    if (!skipDependencies) {
      exposedForTesting.fetchTableAndChartCapsules();
      updateDerivedDataTree();
      fetchTable();
      fetchAnnotations();
    }

    const addedItem = findItemIn(getTrendStores(), item.id);
    if (addedItem && !addedItem.isChildOf) {
      if (_.some(getAllItems({}), 'selected')) {
        exposedForTesting.setItemSelected(item, true);
      }

      if (sqWorkbenchStore.stateParams.workbookId) {
        addRecentlyAccessed(sqWorkbenchStore.stateParams.workbookId, item.id);
      }

      if (addedItem.lane && getAllItems({}).length > 1 && !sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, 'lane')) {
        exposedForTesting.setColumnEnabled(TREND_PANELS.SERIES, 'lane', true, PUSH_IGNORE);
      }

      if (!_.isEmpty(addedItem.assets)) {
        exposedForTesting.setColumnEnabled(TREND_PANELS.SERIES, 'asset', true, PUSH_IGNORE);
      }
    }

    return addedItem;
  });
}

export function batchAddItems(items: any[], isSeparate = true) {
  const extraProps = isSeparate
    ? undefined
    : {
        lane: sqTrendStore.nextLane,
        alignment: sqTrendStore.nextAlignment,
        yAxisType: _.reject(items, isStringSeries)[0]?.yAxisType,
      };
  items.forEach((item) => {
    let newItem = item;
    if (extraProps) {
      newItem = {
        ...item,
        ..._.omitBy(
          {
            lane: extraProps.lane,
            axisAlign: isStringSeries(item) ? undefined : extraProps.alignment,
            yAxisType: isStringSeries(item) ? undefined : extraProps.yAxisType,
          },
          _.isUndefined,
        ),
      };
    }
    exposedForTesting.addTrendItem(newItem);
  });
  if (!isSeparate && _.some(items, isStringSeries) && items.length > 1) {
    infoToast({ messageKey: 'NO_STRING_DATA_Y_AXIS_SHARING' });
  }

  flux.dispatch('SEARCH_CLEAR_SELECTED_ITEMS');
}

/**
 * Adds an Annotation which just involves adding its interests and switching the tab, because the annotation tab will
 * update to include annotations matching the interest.
 *
 * @param {Object} item - An annotation from the REST API.
 * @return {Object} A promise that will be fulfilled when it is finished adding the annotation.
 */
export async function addAnnotation(item) {
  const { data: annotation } = await sqAnnotationsApi.getAnnotation({ id: item.id });
  await showEntry(annotation.id);
  return _.chain(annotation.interests)
    .map('item')
    .filter(isTrendable)
    .map((item) => exposedForTesting.addTrendItem(item))
    .value();
}

/**
 * Adds a CapsuleSet.
 *
 * @param {Object} item - A capsule set item from the REST API.
 * @param {Object} [props] - additional properties to be set on the item before fetching
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 * @return {Object} A promise that will be fulfilled when it is finished fetching.
 */
export function addCapsuleSet(item, props?, option?) {
  if (!sqTrendConditionStore.findItem(item.id)) {
    const payload = {
      id: item.id,
      name: item.name,
      isArchived: !!item.isArchived,
      color: item.color,
      effectivePermissions: item.effectivePermissions,
      lane: item.lane ?? sqTrendStore.nextLane,
      lineWidth: 1,
    };

    const items = getAllItems({});
    const capsuleSetLane = _.chain(items)
      .filter({ itemType: ITEM_TYPES.SERIES || ITEM_TYPES.SCALAR })
      .map('lane')
      .min()
      .value();
    if (capsuleSetLane) {
      payload.lane = capsuleSetLane;

      const itemCustomizations = _.chain(items)
        .filter((item) => item.lane >= payload.lane)
        .map((item) => ({ id: item.id, lane: item.lane + 1 }))
        .value();
      setCustomizationProps(itemCustomizations);

      // Move the target for custom lane labels below newly added condition downward by 1 lane
      const customLaneLabelTargetUpdates: CustomLabelTargetUpdate[] =
        sqTrendStore.labelDisplayConfiguration.customLabels
          .filter((label) => label.location === 'lane' && label.target >= payload.lane)
          .map((label: LaneCustomLabel) => ({ currentTarget: label.target, newTarget: label.target + 1 }));
      exposedForTesting.updateCustomLabelTargets(customLaneLabelTargetUpdates, PUSH_IGNORE);
    }

    flux.dispatch('TREND_ADD_CAPSULE_SET', payload, option);
  }

  exposedForTesting.setTrendItemProps(item.id, props, option);

  // Fetch chart and details first to get it on screen quickly and to ensure formula warnings show (CRAB-11702)
  return Promise.all([
    exposedForTesting.fetchItemProps(item.id).then(() => {
      addCondition({
        id: item.id,
      });
    }),
    exposedForTesting.fetchChartCapsules([item]),
  ]).then(() =>
    Promise.all([
      fetchTreemap(),
      exposedForTesting.fetchTimebarCapsules(item.id),
      exposedForTesting.fetchTableAndChartCapsules(),
      exposedForTesting.fetchStatistics(sqTrendConditionStore.findItem(item.id)),
      fetchMonitoredConditionIds([item.id]),
    ]),
  );
}

/**
 * Adds a series item and fetches the data for it.
 *
 * @param {Object} item - A timeseries item from the REST API.
 * @param {Object} [props] - additional properties to be set on the item before fetching
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 * @return {Object} A promise that will be fulfilled when it is finished fetching.
 */
export function addSeries(item, props?, option?) {
  if (!sqTrendSeriesStore.findItem(item.id)) {
    const payload = {
      id: item.id,
      interpolationMethod: _.find(item?.properties, {
        name: SeeqNames.Properties.InterpolationMethod,
      }),
      name: item.name,
      isArchived: !!item.isArchived,
      lane: item.lane ? item.lane : sqTrendStore.nextLane,
      alignment: item.axisAlign ? item.axisAlign : sqTrendStore.nextAlignment,
      color: item.color,
      effectivePermissions: item.effectivePermissions,
    };

    flux.dispatch('TREND_ADD_SERIES', payload, option);
  }

  exposedForTesting.setTrendItemProps(item.id, props, option);

  return Promise.all([
    exposedForTesting.fetchItemProps(item.id).then(() => {
      addSignal({
        id: item.id,
        trendStores: getTrendStores(),
      });
    }),
    exposedForTesting.fetchTimeseries(item.id),
  ]);
}

/**
 * Adds a scalar item and fetches the data for it.
 *
 * @param {Object} item - A scalar item from the REST API.
 * @param {Object} [props] - additional properties to be set on the item before fetching
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 * @return {Object} A promise that will be fulfilled when it is finished fetching.
 */
export function addScalar(item, props?, option?) {
  if (!sqTrendScalarStore.findItem(item.id)) {
    const payload = {
      id: item.id,
      name: item.name,
      isArchived: !!item.isArchived,
      lane: item.lane ? item.lane : sqTrendStore.nextLane,
      alignment: item.axisAlign ? item.axisAlign : sqTrendStore.nextAlignment,
      color: item.color,
      effectivePermissions: item.effectivePermissions,
    };

    // If we are adding a scalar to a chart with only one lane and alignment displaying, add it to the displayed
    // ones
    const uniqueLanes = sqTrendStore.uniqueLanes;
    const uniqueAlignments = sqTrendStore.uniqueAlignments;
    if (uniqueLanes.length === 1 && uniqueAlignments.length === 1) {
      payload.lane = uniqueLanes[0];
      payload.alignment = uniqueAlignments[0];
    }

    flux.dispatch('TREND_ADD_SCALAR', payload, option);
  }

  exposedForTesting.setTrendItemProps(item.id, props, option);

  return Promise.all([exposedForTesting.fetchItemProps(item.id), exposedForTesting.fetchScalar(item.id)]);
}

/**
 * Adds a metric item and fetches the data for it. Also adds the metric bounding condition if the process type is
 * Batch.
 *
 * @param {Object} item - A scalar item from the REST API.
 * @param {Object} [props] - additional properties to be set on the item before fetching
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 * @return {Object} A promise that will be fulfilled when it is finished fetching.
 */
export function addMetric(item, props?, option?) {
  if (!sqTrendMetricStore.findItem(item.id)) {
    const payload = {
      id: item.id,
      lane: item.lane ? item.lane : sqTrendStore.nextLane,
      alignment: item.axisAlign ? item.axisAlign : sqTrendStore.nextAlignment,
      name: item.name,
      isArchived: !!item.isArchived,
      color: item.color,
      effectivePermissions: item.effectivePermissions,
    };

    flux.dispatch('TREND_ADD_METRIC', payload, option);
  }

  exposedForTesting.setTrendItemProps(item.id, props, option);

  return Promise.all([exposedForTesting.fetchItemProps(item.id)]).then(() => {
    // If metric is Condition then automatically add the bounding condition to the trend when the metric is added
    const addedItem = findItemIn(getTrendStores(), item.id);
    const metricProcessType = _.get(addedItem, 'definition.processType');
    const metricBoundingCondition = _.get(addedItem, 'definition.boundingCondition');
    if (metricProcessType === ProcessTypeEnum.Condition && metricBoundingCondition) {
      exposedForTesting.addTrendItem(metricBoundingCondition, props, PUSH_IGNORE);
    }
    if (metricProcessType === ProcessTypeEnum.Condition || metricProcessType === ProcessTypeEnum.Continuous) {
      addMetricColumn(item);
    }
  });
}

/**
 * Given a specification for an asset swap, calculates the new ID for each item to be swapped
 * using the findSwap API endpoint.
 *
 * @param {SwapPairs} swapPairsForItems - each key is an item ID; each value is the list of swap pairs to be used
 *    for the swap
 * @param {string[]} allItems - an array containing the IDs of all current trend items. Usually the output from
 *    getAllItems from trendDataHelper
 * @param {string[]} itemIdsToSwap - an array containing the IDs out of allItems that should be swapped
 * @param {boolean} suppressWarnings - if true, this function will not raise toast messages to the user for swaps
 *    that don't exist and swaps that are already in the details pane
 * @returns {Object} - dictionary mapping old items IDs to their swapped item IDs
 */
export function getSwaps(swapPairsForItems: SwapPairs, allItems, itemIdsToSwap, suppressWarnings = false) {
  return _.chain(itemIdsToSwap)
    .filter((id: any) => _.has(swapPairsForItems, id))
    .map(function (id: string) {
      return sqItemsApi
        .findSwap(swapPairsForItems[id], { id })
        .then(({ data }) => ({ id, swappedId: data.id }))
        .catch(function (e) {
          if (e.status === HttpCodes.BAD_REQUEST && !suppressWarnings) {
            warnToast({ httpResponseOrError: e });
          }
        });
    })
    .thru((promises) => Promise.all(promises as Promise<any>[]))
    .value()
    .then(_.compact)
    .then(function (swaps) {
      // A swap is invalid if it would replace the current item with one that is already in the store;
      // therefore, adding it would cause a duplicate in the Details panel (CRAB-11603).
      const invalidSwaps = _.filter(swaps, (swap) => _.includes(_.map(allItems, 'id'), swap.swappedId));
      if (!_.isEmpty(invalidSwaps)) {
        const invalidSwapOutItemNames = _.map(invalidSwaps, (swap) => _.find(allItems, ['id', swap.id]).name).join(
          ', ',
        );
        if (!suppressWarnings) {
          warnToast({
            messageKey: 'SWAPS.INVALID_SWAPS',
            messageParams: {
              INVALID_SWAP_OUT_ITEM_NAMES: invalidSwapOutItemNames,
            },
          });
        }

        // Although we don't want to swap items that are already in the details pane, we do need to update the
        // worksheet store's conditionToSeriesGrouping mapping to ensure the groupings point to the correct signal.
        flux.dispatch('WORKSHEET_SWAP_GROUPINGS', {
          swaps: _.zipObject(_.map(invalidSwaps, 'id'), _.map(invalidSwaps, 'swappedId')),
        });
      }
      return _.difference(swaps, invalidSwaps);
    })
    .then((swaps) => _.zipObject(_.map(swaps, 'id'), _.map(swaps, 'swappedId')));
}

/**
 * Swap out one asset for another for all items on the trend. Each item that relies upon signals in the out asset
 * will be swapped for a corresponding version that uses the in asset.
 *
 * @param {SwapPairs} swapPairsForItems - each key is an item ID; each value is the list of
 * swap pairs to be used for the swap
 *
 * @return {Promise} Resolves when all assets have been swapped and data refreshed
 */
export function swapAssets(swapPairsForItems: SwapPairs) {
  const allItems = getAllItems({});
  const itemIdsToSwap = _.chain(allItems).uniqBy('id').map('id').value();

  return getSwaps(swapPairsForItems, allItems, itemIdsToSwap)
    .then((swaps) => {
      if (_.isEmpty(swaps)) {
        return null;
      }
      _.forEach(swaps, (swappedId, id) => {
        exposedForTesting.removeChildren(id);
        // Remove other signals from the chart so that old data and new data is not mixed while loading
        flux.dispatch('TREND_SERIES_CLEAR_DATA', { id });
      });
      closeInvestigationTool();

      flux.dispatch('TREND_SWAP_ITEMS', { swaps });
      successToast({
        messageKey: 'SWAPS.SUCCESS',
        messageParams: {
          SUCCESSFUL_SWAPS_COUNT: _.size(swaps),
          ALL_ITEMS_COUNT: allItems.length,
        },
      });
    })
    .then(() => exposedForTesting.fetchAllItems());
}

/**
 * Removes an item and, optionally, its children from the chart. When the removed item is a series or capsule, if
 * there are children for the item, remove them too.
 *
 * @param {Object} item - The item to remove.
 * @param {Boolean} [keepChildren] - True to prevent children of the item from being removed when the item is
 *   removed. (e.g. When you delete a series, don't delete the capsules that are interested in that series)
 */
export function removeTrendItem(item, keepChildren = false) {
  exposedForTesting.removeItems([item], keepChildren);
}

/**
 * Removes an array of items and, optionally, their children from the chart. When the removed item is a series or
 * capsule, if there are children for the item, remove them too.
 *
 * @param {Object[]} items - The  array of items to remove.
 * @param {Boolean} [keepChildren] - True to prevent children of the item from being removed when the item is
 *   removed. (e.g. When you delete a series, don't delete the capsules that are interested in that series)
 * @param {String} [option] - One of the WORKSTEP_PUSH constants
 */
export async function removeItems(items: any[], option?, keepChildren = false) {
  // Short-circuit and avoid the work if not removing anything
  if (!items.length) {
    return;
  }

  items = _.flatMap(items, (item) =>
    !keepChildren || item.itemType === ITEM_TYPES.METRIC ? [item].concat(findAncestors(item)) : [item],
  );

  _.forEach(items, _.flow(_.property('id'), cancelGroup));
  flux.dispatch('TREND_REMOVE_ITEMS', { items, numPixels: Math.min(chartWidth, getMaxSeriesPixels()) }, option);

  updateDerivedDataTree();

  _.chain(items)
    .reject('isChildOf')
    .map('id')
    .forEach((id) => addRecentlyAccessed(sqWorkbenchStore.stateParams.workbookId, id))
    .value();

  if (_.some(items, (item) => item.itemType === ITEM_TYPES.SERIES)) {
    fetchPlot().catch((e) => logWarn(formatMessage`Error reloading xy plot after removing item: ${e}`));
  }

  if (_.some(items, (item) => item.itemType === ITEM_TYPES.CONDITION)) {
    await Promise.all([
      exposedForTesting.fetchTableAndChartCapsules(),
      exposedForTesting.fetchChartCapsules(),
      exposedForTesting.fetchAllTimebarCapsules(),
      fetchTreemap(),
      fetchPlot(),
    ]).catch((e) => logWarn(formatMessage`Error reloading condition data after removing item: ${e}`));
  }

  if (
    _.some(items, (item) =>
      _.includes([ITEM_TYPES.SCALAR, ITEM_TYPES.SERIES, ITEM_TYPES.CONDITION, ITEM_TYPES.METRIC], item.itemType),
    )
  ) {
    fetchTable();
    fetchAnnotations().catch((e) => logWarn(formatMessage`Error fetching annotations after removing item: ${e}`));
  }

  removeGaps();
  updateLaneDisplay();

  if (sqTrendStore.capsuleTimeLimited.enabled) {
    exposedForTesting.addCapsuleTimeSegments();
  }

  if (_.some(items, ['id', sqInvestigateStore.item.id])) {
    clearInvestigatedItem();
  }

  /**
   * Recursively find all ancestors of the item by searching all stores for children of the item.
   * @param {Object} item - The parent item
   * @returns {Object[]} Array of ancestors
   */
  function findAncestors(item) {
    const ancestors = findChildrenIn(getTrendStores(), item.id);

    if (ancestors.length) {
      return ancestors.concat(_.flatMap(ancestors, findAncestors));
    } else {
      return _.compact(ancestors);
    }
  }
}

/**
 * Removes all items from the chart.
 */
export function removeAllItems() {
  exposedForTesting.removeItems(getAllItems({}));
}

/**
 * Clears the chart by removing all items, canceling any ongoing operations, and resetting the chart defaults,
 * without resetting the duration.
 */
export function clear() {
  exposedForTesting.removeAllItems();
  exposedForTesting.removeTrendSelectedRegion();
  cancelAll();
}

/**
 * Removes all the selected items.
 *
 */
export function removeSelectedItems() {
  _.chain(getAllItems({})).filter(['selected', true]).tap(exposedForTesting.removeItems).value();
}

/**
 * Toggles the selection of an item.
 *
 * @param {Object} item - The item to toggle.
 */
export function toggleTrendItemSelected(item, forceTableRefresh = false) {
  exposedForTesting.setItemSelected(item, !item.selected, forceTableRefresh);
}

/**
 * Sets the selection of an item. Because the user or the select all button can trigger this function in rapid
 * succession all async calls are debounced.
 *
 * @param {Object} item - The item to toggle.
 * @param selected - True to select the item; false to unselect.
 * @param [forceTableRefresh] - True to refresh table/chart capsules; defaults to false.
 * @param [fetchTableData] - True to fetch data for the Simple or Condition Table, false to skip this. Defaults true.
 */
export function setItemSelected(item, selected: boolean, forceTableRefresh = false, fetchTableData = true) {
  // Get item from the store to ensure item props are present (e.g. childType) because they may not be present
  // in the supplied item (e.g. when the item is initially added to the trend), fallback to use the provided item
  // if no store item can be found to ensure capsule selection of capsules not in the capsule table still works.
  item = findItemIn(getTrendStores(), item.id) || item;
  // If item is a composite child then replace it with the parent
  item = substituteParentforChild(item);

  flux.dispatch('TREND_SET_SELECTED', { item, selected, numPixels: chartWidth });

  if (fetchTableData) {
    fetchTableDebounced();
  }

  const isCapsuleSetOrForceRefresh = item.itemType === ITEM_TYPES.CONDITION || forceTableRefresh;
  const isCapsule = item.itemType === ITEM_TYPES.CAPSULE;
  if (isCapsuleSetOrForceRefresh || (isCapsule && sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT)) {
    fetchConditionsDebounced();
  }
  if (sqInvestigateStore.activeTool === TREND_TOOLS.MANUAL_CONDITION && (isCapsuleSetOrForceRefresh || isCapsule)) {
    triggerManualConditionPreviewDebounced();
  }

  if (sqTrendStore.hideUnselectedItems) {
    fetchHiddenDebounced();
    if (item.itemType !== ITEM_TYPES.CAPSULE) {
      updateLaneDisplay();
    }

    const items = getAllItems({});
    if (selected) {
      // We've newly selected an item, so fetch its statistics
      fetchStatisticsDebounced(item);
      // And unset all non-selected items' statistics
      clearStatisticsForUnselectedItems(items);
    } else if (!items.some((item) => item.selected)) {
      // We've unselected the last selected item, so fetch all statistics
      fetchAllStatisticsDebounced();
    } else {
      // We've unselected the item, so unset its statistics
      clearStatisticsForUnselectedItems([{ ...item, selected: false }]);
    }
  }

  fetchCapsuleTimeDebounced();
}

/**
 * Handles selecting one or more items. MultiSelect mode is toggled by using the ctrl/meta key and causes the item
 * to be toggled without affecting the state of other items.  Without the modifier key there are two behaviors:
 * - If multiple items are selected then the item will now be the selected one, even if it was already selected.
 * - If only a single item is selected then the item's selection will be toggled.
 *
 * @param {Object} item - The item to be selected
 * @param {Object[]} items - The collection that this item belongs to
 * @param {Object} event - The click event
 */
export function selectTrendItems(item, items, event) {
  let singleItemSelected;
  const multiSelect = isApplePlatform() ? event.metaKey : event.ctrlKey;
  const isCapsulePickingMode = item.itemType === ITEM_TYPES.CAPSULE && sqInvestigateStore.isCapsulePickingMode;
  if (isCapsulePickingMode && _.includes(sqTrendStore.allSelectedRegionOptionsPlugins, sqInvestigateStore.activeTool)) {
    return;
  }

  if (multiSelect || isCapsulePickingMode) {
    exposedForTesting.toggleTrendItemSelected(item);
  } else {
    // Capsules behave differently since selection can include elements not in items
    if (item.itemType === ITEM_TYPES.CAPSULE) {
      singleItemSelected = sqTrendCapsuleStore.selectedCapsules.length === 1;
      exposedForTesting.unselectAllCapsules();
    } else {
      ({ item, items } = processItems(item, items));
      singleItemSelected = _.filter(items, 'selected').length === 1;
      _.forEach(items, (item) => flux.dispatch('TREND_SET_SELECTED', { item, selected: false }));
    }

    // If multiple items were previously selected, we want to select only the new item and clear other selections
    // (regardless of whether the passed in item was selected or not). If only one item was previously selected, we
    // want to toggle the item's selection status instead.
    if (singleItemSelected) {
      exposedForTesting.toggleTrendItemSelected(
        item,
        _.some(items, { selected: false, itemType: ITEM_TYPES.CONDITION }),
      );
    } else {
      exposedForTesting.setItemSelected(item, true, true);
    }
  }
}

/**
 * Substitutes the parent for any composite child in the supplied items. A composite child is one that
 * must be treated as part of the whole parent item from the perspective of trend selection. Returns the item and
 * items arguments unchanged if they do not contain a composite child.
 *
 * @param {Object} item - an item
 * @param {Object[]} items - an array of items
 * @returns {{item: any; items: any}} an object containing the possibly-updated item and items
 */
export function processItems(item, items) {
  items = _.chain(items)
    .map((item) => substituteParentforChild(item))
    .uniq()
    .value();

  if (isCompositeChild(item)) {
    const parent = _.cloneDeep(substituteParentforChild(item));
    parent.selected = item.selected;
    item = parent;
  }
  return { item, items };
}

/**
 * If the supplied item is a composite child then the parent is returned instead. Otherwise the item is returned
 * unchanged.
 *
 * @param {Object} item - an item
 * @returns {Object} an item; possibly a parent in place of the supplied composite child.
 */
export function substituteParentforChild(item) {
  if (isCompositeChild(item)) {
    return findItemIn(getTrendStores(), item.isChildOf);
  } else {
    return item;
  }
}

/**
 * Determines if an item is a child that must be treated as part of the whole parent item from the perspective of
 * trend selection.
 *
 * @param {Object} item - an item
 * @returns {Boolean} true if the item is a composite child, false otherwise
 */
export function isCompositeChild(item) {
  const childrenTypes = [
    ITEM_CHILDREN_TYPES.METRIC_DISPLAY,
    ITEM_CHILDREN_TYPES.METRIC_THRESHOLD,
    ITEM_CHILDREN_TYPES.ANCILLARY,
  ];
  return _.includes(childrenTypes, item.childType);
}

/**
 * Overwrite the selected capsules with the provided capsules.
 *
 * @param {Object[]} capsules - Selected capsules
 * @param {String} conditions[].id - capsule's unique id created from `getUniqueId`
 * @param {Number} conditions[].startTime - end of the capsule in ms
 * @param {Number} conditions[].endTime - start of capsule in ms
 * @param {Boolean} conditions[].isUncertain - indicates that the capsule is uncertain
 */
export function replaceCapsuleSelection(capsules) {
  flux.dispatch('TREND_REPLACE_CAPSULE_SELECTION', { capsules });
}

/**
 * Unselects all capsules.
 */
export function unselectAllCapsules() {
  flux.dispatch('TREND_UNSELECT_ALL_CAPSULES');
  if (sqTrendStore.hideUnselectedItems) {
    flux.dispatch('TREND_REMOVE_ALL_CAPSULE_SERIES');
    // Reload capsules and data when we are in capsule time view and "Dimming" -> "Show only selected" is on.
    // Otherwise, we have no data for the unselected capsules.
    exposedForTesting.addCapsuleTimeSegments(true);
  }
  // trigger fetch data when we are in chain view
  exposedForTesting.createStitchDetails();
  // trigger fetch data when in xyplot
  if (sqWorksheetStore.view.key === WORKSHEET_VIEW.SCATTER_PLOT) {
    fetchPlot();
  }
}

/**
 * Selects a set of capsules by dispatching a single event for all of them.
 * @param capsules - The set of capsules.
 */
export function selectCapsules(capsules: any[]) {
  flux.dispatch('TREND_SET_SELECTED_CAPSULES', { capsules, numPixels: chartWidth });
}

/**
 * Sets the pointer x and y values that correspond to where the mouse pointer is on the chart.
 *
 * @param {Number} xValue - The x-value timestamp.
 * @param {Array} yValues - An array of y-values for the items on the chart.
 */
export function setPointerValues(xValue, yValues) {
  flux.dispatch('TREND_SET_X_VALUE', { xValue }, PUSH_IGNORE);
  flux.dispatch('TREND_SET_POINT_VALUE', { yValues }, PUSH_IGNORE);
}

/**
 * Clears the pointer values.
 */
export function clearPointerValues() {
  flux.dispatch(
    'TREND_SET_X_VALUE',
    {
      xValue: null,
    },
    PUSH_IGNORE,
  );
  flux.dispatch('TREND_CLEAR_POINT_VALUES', undefined, PUSH_IGNORE);
}

/**
 * Compute the number of lanes to use for the chart. We don't want to sacrifice chart fidelity by choosing a
 * number that's too low, but choosing a huge number will result in more data than necessary being fetched
 * (CRAB-13743). Use the next common screen resolution width greater than or equal to the browser window so we don't
 * lose chart fidelity. If we go beyond resolutions listed (currently at 8k), double the value until
 * it exceeds the width of the browser.
 */
export function computeChartWidth(windowWidth: number) {
  if (headlessCaptureMetadata().category === HeadlessCategory.Thumbnail) {
    // Thumbnails should always pretend to have a really wide chart width. This seems counter-intuitive because it
    // seems like we are asking appserver for extra data, however it reduces the laneWidth that is passed to
    // spikeCatcher which means that less data will need to be requested to to satisfy the lanes at the edges of
    // the display window. Which can save round trips to datasources because the data might be cached. See CRAB-15579
    return _.last(CHART_THRESHOLDS);
  }

  let threshold = _.reduceRight(
    CHART_THRESHOLDS,
    (currentThreshold, threshold) => (threshold >= windowWidth ? threshold : currentThreshold),
    _.last(CHART_THRESHOLDS),
  );

  while (windowWidth > threshold) {
    // The window is using more pixels than the largest resolution listed in chartThresholds.
    threshold *= 2;
  }

  return threshold;
}

/**
 * Fetches timeseries data based on the current view.
 */
export function fetchDataBasedOnView(requests: any[] = []) {
  switch (sqTrendStore.view) {
    case TREND_VIEWS.CALENDAR:
      requests.push(exposedForTesting.fetchAllTimeseries());
      break;
    case TREND_VIEWS.CHAIN:
      requests.push(exposedForTesting.createStitchDetails());
      break;
    case TREND_VIEWS.CAPSULE:
      requests.push(exposedForTesting.addCapsuleTimeSegments(true));
      requests.push(exposedForTesting.fetchAllTimeseries());
      break;
  }
}

/**
 * Sets the width of the chart and then re-fetches timeseries data when the browser size increases
 * beyond a CHART_THRESHOLD. Always resize chartWidth to a value greater than or equal to the number of pixels
 * of the chart so wo don't lose chart fidelity (CRAB-13743).
 *
 * @param windowWidth - The width of the window
 * @param forceSetForTests - Normally width is not set if the size decreases, but this can be overridden for tests
 */
export function setChartWidth(windowWidth = window.innerWidth, forceSetForTests = false) {
  if (headlessRenderMode()) {
    // CRAB-26006 - in headless mode, page.setViewport may be called to adjust the width, so that the screenshot
    // contains the whole content (e.g. very wide table). In this case we do not want to trigger any data fetch.
    return;
  }

  const newChartWidth = computeChartWidth(windowWidth);
  flux.dispatch('AUTO_UPDATE_SET_DISPLAY_PIXELS', { displayPixels: newChartWidth }, PUSH_IGNORE);

  if (newChartWidth <= chartWidth && !forceSetForTests) {
    // Only need to fetch (downsampled) data if chart is getting larger than threshold.
    return;
  }

  chartWidth = newChartWidth;

  const fetchRequests = [fetchAllFftTables(), fetchMinimapSignals()];
  exposedForTesting.fetchDataBasedOnView(fetchRequests);
  return Promise.all(fetchRequests);
}

/**
 * Returns the chart width in pixels.
 *
 * returns the chart width in pixels.
 */
export function getChartWidth(): number {
  return chartWidth;
}

/**
 * Fetch the data for all items used by the different charts and views. Any updates to this method should also
 * considered in the context fetchItems.
 *
 * @param {Object} [options] - Options to configure what is fetched
 * @param {Boolean} [options.skipProps] - Skip the fetching of properties
 * @param {Boolean} [options.skipTimebar] - Skip fetching timebar capsules
 *
 * @returns {Promise} A promise that resolves when all the data is fetched.
 */
export function fetchAllItems({ skipProps = false, skipTimebar = false } = {}) {
  // None of this async data is needed in an organizer topic
  if (sqWorkbookStore.isReportBinder) {
    return Promise.resolve([]);
  }

  const promises = [];
  const fetchPropsPromise = skipProps
    ? Promise.resolve()
    : exposedForTesting.fetchPropsForAllItems(true).catch((e) => !isCanceled(e) && logError(e));
  // Treemap needs to know assets before it can fetch and table needs to know assets and if signal is string
  promises.push(fetchPropsPromise.then(() => Promise.all([fetchTreemap(), fetchTable(), updateDerivedDataTree()])));
  promises.push(fetchPlot());
  if (!skipProps) {
    promises.push(fetchMonitoredConditionIds(sqTrendConditionStore.items.map((item) => item.id)));
  }

  if (sqTrendStore.view !== TREND_VIEWS.CAPSULE) {
    promises.push(
      sqTrendStore.view === TREND_VIEWS.CHAIN
        ? exposedForTesting.fetchAllStatistics(true)
        : exposedForTesting.fetchAllTimeseries(!skipProps),
    );
  }
  const isChainOrCapsuleView = sqTrendStore.view === TREND_VIEWS.CHAIN || sqTrendStore.view === TREND_VIEWS.CAPSULE;
  promises.push(isChainOrCapsuleView ? undefined : exposedForTesting.fetchPreviewSeries());

  promises.push(fetchAllTables());
  promises.push(exposedForTesting.fetchAllScalars(!skipProps));
  // The request for capsules must be done before the request for capsules table. Otherwise the warnings are
  // swallowed by the last one. This specific order is needed until CRAB-8020 will be solved.
  flux.dispatch('TREND_SET_CAPSULE_PANEL_IS_LOADING', { isLoading: true }, PUSH_IGNORE);
  promises.push(
    exposedForTesting.fetchChartCapsules().then(() => {
      exposedForTesting.fetchTableAndChartCapsules();
    }),
  );
  promises.push(skipTimebar ? undefined : exposedForTesting.fetchAllTimebarCapsules());
  return Promise.all(promises);
}

/**
 * Fetch the data for items used by the different charts and views. Any updates to this method should also
 * considered in the context of fetchAllItems.
 *
 * @param {Object[]} items - Array of items currently in the chart
 * @param {Object} options - Options for fetching
 * @param {boolean} options.fetchFailed - True to force it to also fetch FAILED conditions
 * @param {boolean} options.fetchCapsulesLater - True to skip fetching capsules before fetching everything else
 * @returns {Promise} A promise that resolves when all the data is fetched.
 */
export function fetchItems(items: any[], options = { fetchFailed: false, fetchCapsulesLater: false }) {
  const isConditionPresent = _.some(items, ['itemType', ITEM_TYPES.CONDITION]);
  return (
    isConditionPresent && !options.fetchCapsulesLater
      ? // A workaround for the fact that warnings do not show for cached data and conditions fire off three requests
        // (chart, timebar, and table) which means two of those will use cached data. So, to allow the user to see
        // formula warnings when updating a formula the chart capsules complete first (CRAB-11702).
        exposedForTesting.fetchChartCapsules(_.filter(items, ['itemType', ITEM_TYPES.CONDITION]))
      : // In the case where a users items are completely errored out, it is very confusing to have the capsules load
        // first, so in that case we postpone retrieving chart capsules until later on (CRAB-16686)
        Promise.resolve()
  ).then(() => {
    const propertyPromises = [];
    const promises = [];

    _.forEach(items, (item: any) => {
      propertyPromises.push(exposedForTesting.fetchItemProps(item.id));
      switch (item.itemType) {
        case ITEM_TYPES.CONDITION:
          if (options.fetchCapsulesLater) {
            promises.push(exposedForTesting.fetchChartCapsules([item]));
          }
          promises.push(exposedForTesting.fetchTimebarCapsules(item.id));
          promises.push(exposedForTesting.fetchStatistics(item));
          break;
        case ITEM_TYPES.SERIES:
          promises.push(exposedForTesting.fetchTimeseries(item.id));
          break;
        case ITEM_TYPES.SCALAR:
          promises.push(exposedForTesting.fetchScalar(item.id));
          break;
        case ITEM_TYPES.TABLE:
          promises.push(fetchTableData(item.id));
          break;
      }
    });

    promises.push(
      Promise.all(propertyPromises).then(function () {
        return [
          // Properties must be set before we can update the derived data tree
          updateDerivedDataTree(),
          // Assets must be fetched before treemap can be updated
          fetchTreemap(),
        ];
      }),
    );

    const statisticsDisplayed = _.chain(items)
      .map('id')
      .intersection(_.map(sqTrendStore.customColumns(TREND_PANELS.CAPSULES), 'referenceSeries'))
      .some()
      .value();

    if (isConditionPresent || statisticsDisplayed) {
      promises.push(exposedForTesting.fetchTableAndChartCapsules(options));
    }

    promises.push(fetchPlot());
    promises.push(fetchTable());

    return Promise.all(promises);
  });
}

/**
 * HIDDEN_FROM_TREND is set when an item is hidden from the trend rather than fetching data that won't be seen. If
 * the item becomes visible again, this method handles loading the data that we deferred loading. This is
 * essentially a slimmed down version of fetchItems
 */
export function fetchHiddenTrendData() {
  const [conditions, otherItems] = _.chain(
    getAllItems({
      itemChildrenTypes:
        sqTrendStore.view === TREND_VIEWS.CAPSULE
          ? [ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE]
          : [ITEM_CHILDREN_TYPES.METRIC_DISPLAY, ITEM_CHILDREN_TYPES.METRIC_THRESHOLD],
    }),
  )
    .filter(['dataStatus', ITEM_DATA_STATUS.HIDDEN_FROM_TREND])
    .partition(['itemType', ITEM_TYPES.CONDITION])
    .value();
  const promises = [];

  if (_.some(conditions)) {
    promises.push(exposedForTesting.fetchChartCapsules(conditions));
  }
  _.forEach(otherItems, (item: any) => {
    switch (item.itemType) {
      case ITEM_TYPES.SERIES:
        promises.push(exposedForTesting.fetchTimeseries(item.id));
        break;
      case ITEM_TYPES.SCALAR:
        promises.push(exposedForTesting.fetchScalar(item.id));
        break;
      case ITEM_TYPES.TABLE:
        promises.push(fetchTableData(item.id));
        break;
    }
  });

  return Promise.all(promises);
}

/**
 * Fetches the specified item and all of its calculation dependencies
 *
 * @param {String} id - ID of item
 *
 * @returns {Promise} that resolves when the item and all of its dependencies have been fetched
 */
export function fetchItemAndDependents(id: string) {
  return sqItemsApi.getFormulaDependents({ id }).then(({ data }) =>
    _.chain(data.items)
      .map('id')
      .concat(id)
      .thru((ids) => {
        const children = _.chain(getAllChildItems({})).filter((item) =>
          _.some(['interestId', 'shadedAreaLower.id', 'shadedAreaUpper.id'], (path) =>
            _.includes(ids, _.get(item, path)),
          ),
        );

        const parentIds = children.map('isChildOf').value();
        return _.concat(ids, parentIds);
      })
      .uniq()
      .map((itemId) => findItemIn(getTrendStores(), itemId))
      .compact()
      .thru((items) => exposedForTesting.fetchItems(items))
      .value(),
  );
}

/**
 * Fetches "non capsule series" data (such as signals in the details panel).
 *
 * Computes a unique cancellation group name based on the
 * items so that if the another set of requests is made for the same items the first are canceled before fetching
 * the next batch. Calls to this function should still be debounced to ensure that there are not repeated calls
 * and cancels.
 *
 * @param {boolean} skipChildren - if true, no data is fetched for child items
 *
 * @return {Promise} A promise that resolves when all non-capsule series have been fetched
 */
export function fetchAllTimeseries(skipChildren?: boolean) {
  const items =
    sqTrendStore.view === TREND_VIEWS.CAPSULE
      ? sqTrendSeriesStore.capsuleSeries
      : groupedNonCapsuleSeries({
          nonCapsuleSeries: sqTrendSeriesStore.nonCapsuleSeries,
        });

  return _.chain(items)
    .reject((item) => isItemRedacted(item))
    .reject((item: any) => skipChildren && item.isChildOf)
    .map((item: any) => exposedForTesting.fetchTimeseries(item.id))
    .thru((promises) => Promise.all(promises))
    .value();
}

/**
 * Fetches the timeseries data and then updates statistics. Handles both regular series and series converted from
 * capsules since the start/end times and fetched series are dependent on that property. This method is
 * dependent on the `chartWidth` variable because that determines how much data to fetch.
 * This method also loads any custom properties specified by the user. Properties are only loaded once as they are
 * assigned to the asset and don't change as the time range changes.
 *
 * @param {String} itemId - The id of the series item to fetch.
 *
 * @return {Promise} Promise that is resolved with the plot values for the series
 */
export function fetchTimeseries(itemId: string): Promise<any> {
  let item = sqTrendSeriesStore.findItem(itemId);
  const cancellationGroup = `fetchTimeseries${CANCELLATION_GROUP_GUID_SEPARATOR}${itemId}`;

  // If loading for the first time we won't have a chartWidth, so no need to fetch data that will be overwritten as
  // soon as the chart is instantiated.
  if (!_.isNumber(chartWidth)) {
    return Promise.resolve();
  }

  if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND) {
    return Promise.resolve();
  }

  if (
    isHidden({
      item,
    })
  ) {
    flux.dispatch('TREND_SET_DATA_STATUS_HIDDEN_FROM_TREND', { id: item.id }, PUSH_IGNORE);
    return Promise.resolve();
  }

  const numPixels = Math.min(chartWidth, getMaxSeriesPixels());

  if (!_.includes([WORKSHEET_VIEW.TREND, WORKSHEET_VIEW.TABLE], sqWorksheetStore.view.key)) {
    // Scatterplot and Treemap views fetch their own data
    return Promise.resolve();
  }

  if (sqTrendStore.view === TREND_VIEWS.CAPSULE && item.childType !== ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
    // In capsule view, Series from Capsule segments are be passed instead of the parent signal
    return Promise.resolve();
  }

  if (sqTrendStore.view !== TREND_VIEWS.CAPSULE && item.childType === ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
    // Series from Capsule segments should never be fetched outside of chain view
    return Promise.resolve();
  }

  // Reset all enabled statistics for this item before requesting new values
  flux.dispatch('TREND_SET_STATISTICS', { id: item.id, statistics: {} }, PUSH_IGNORE);

  let startTime = sqDurationStore.displayRange.start;
  let endTime = sqDurationStore.displayRange.end;
  const range: RangeExport = {
    start: startTime,
    end: endTime,
    duration: moment.duration(endTime.valueOf() - startTime.valueOf()),
  };

  // Items that specify fragments are formula functions that will be evaluated by fetchOneSignal
  if (sqTrendStore.view !== TREND_VIEWS.CHAIN || item.fragments) {
    flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: item.id }, PUSH_IGNORE);
    cancelGroup(cancellationGroup, true);

    let seriesRequest;
    if (item.shadedAreaUpper && item.shadedAreaLower) {
      seriesRequest = fetchPairedSignal(
        item.shadedAreaLower,
        item.shadedAreaUpper,
        range,
        numPixels,
        cancellationGroup,
      );
    } else {
      // This is a "normal" signal in the Trend view
      seriesRequest = fetchOneSignal(getItemId(item), range, numPixels, item.fragments, cancellationGroup);
    }

    return seriesRequest
      .then((results) => {
        if (!sqTrendStore.isRegionSelected) {
          exposedForTesting.updateStatistics(item, results);
        } else {
          // this could be a result of a reload, so we need to fetch the statistics for the selected region
          exposedForTesting.fetchStatistics(item);
        }

        const payload = _.assign(
          { id: item.id },
          _.pick(results, [
            'samples',
            'valueUnitOfMeasure',
            'timingInformation',
            'meterInformation',
            'warningLogs',
            'warningCount',
            'interpolationMethod',
          ]),
        );
        flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', payload, PUSH_IGNORE);
        updateLaneDisplay();

        return payload;
      })
      .catch(_.partial(exposedForTesting.catchItemDataFailure, item.id, cancellationGroup));
  } else {
    // sqTrendStore.view === TREND_VIEWS.CHAIN: this is "Chain View"
    if (_.isEmpty(sqTrendCapsuleStore.stitchTimes)) {
      return Promise.resolve();
    }

    return (
      item.shadedAreaUpper && item.shadedAreaLower
        ? fetchPairedSignalWithinCondition(
            item.isChildOf,
            item.shadedAreaLower,
            item.shadedAreaUpper,
            range,
            numPixels,
            cancellationGroup,
          )
        : fetchOneSignalWithinCondition(getItemId(item), numPixels, cancellationGroup)
    )
      .then(function (data) {
        if (!sqTrendStore.isRegionSelected) {
          exposedForTesting.updateStatistics(item, data);
        } else {
          // this could be a result of a reload, so we need to fetch the statistics for the selected region
          exposedForTesting.fetchStatistics(item);
        }
        const payload = _.assign(
          {
            id: itemId,
            valueUnitOfMeasure: data.valueUnitOfMeasure,
            samples: data.samples,
          },
          _.pick(data, ['timingInformation', 'meterInformation', 'warningLogs', 'warningCount', 'interpolationMethod']),
        );

        flux.dispatch('TREND_SERIES_RESULTS_SUCCESS', payload, PUSH_IGNORE);
        updateLaneDisplay();
        return payload;
      })
      .catch(_.partial(exposedForTesting.catchItemDataFailure, item.id, cancellationGroup));
  }
}

/**
 * Fetches a set of signal segments in one request by creating a manual condition with all the capsules. This
 * method is dependent on the `chartWidth` variable because that determines how much data to fetch.
 *
 * @param itemId - The id of the series item to fetch.
 * @param capsules - A set of capsules used to determine the signal segments which will be loaded.
 * @return {Promise} Promise that is resolved with the plot values for the series
 */
export function fetchSignalSegments(itemId: string, capsules) {
  if (_.isEmpty(capsules) || !_.isNumber(chartWidth)) {
    return Promise.resolve();
  }

  flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: itemId }, PUSH_IGNORE);

  const cancellationGroup = `loadSignalSegments${CANCELLATION_GROUP_GUID_SEPARATOR}${itemId}`;

  const item = sqTrendSeriesStore.findItem(itemId);
  const stats = _.chain(item.itemType === ITEM_TYPES.SERIES ? TREND_SIGNAL_STATS : TREND_CONDITION_STATS)
    .filter((stat: any) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, stat.key))
    .filter((stat: any) => (isStringSeries(item) ? stat.isStringCompatible : true))
    .value();
  const enums = stats.length ? `, ${_.map(stats, 'stat').join(', ')}` : '';

  cancelGroup(cancellationGroup, true);
  const { formula, limit, capsulesQueryIntervals, range } = buildSignalSegmentsFormula(
    itemId,
    capsules,
    chartWidth,
    sqTrendSeriesStore.longestCapsuleSeriesDuration,
    enums,
  );

  return computeSamples({
    usePost: true, // Needed in case the condition formula is long
    id: itemId,
    range,
    formula,
    limit,
    cancellationGroup,
  })
    .then((result) => {
      const {
        samples,
        valueUnitOfMeasure,
        meterInformation,
        timingInformation,
        warningCount,
        warningLogs,
        interpolationMethod,
        table,
      } = result;
      flux.dispatch(
        'TREND_MULTIPLE_SERIES_DATA_RESULTS_SUCCESS',
        {
          requestCapsules: capsulesQueryIntervals,
          id: itemId,
          samples,
          valueUnitOfMeasure,
          timingInformation,
          meterInformation,
          interpolationMethod,
        },
        PUSH_IGNORE,
      );
      if (table && table.data) {
        updateStatistics(item, result);
      }
    })
    .catch(_.partial(exposedForTesting.catchItemDataFailure, itemId, cancellationGroup));
}

/**
 * Updates the series statistics with those returned by spikecatcher
 *
 * @param {Object} item - The item to be updated
 * @param {Object} results - the results returned by spikecatcher
 * @param {Object} results.table - the statistics table
 **/
export function updateStatistics(item, results) {
  if (results.table) {
    const stats = _.chain(TREND_SIGNAL_STATS)
      .filter((stat: any) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, stat.key))
      .filter((stat: any) => (results.valueUnitOfMeasure === STRING_UOM ? stat.isStringCompatible : true))
      .value();
    const table = results.table;
    let dispatchItemId = item.id;

    if (item.childType === ITEM_CHILDREN_TYPES.METRIC_DISPLAY) {
      const parentMetric = sqTrendMetricStore.findItem(item.isChildOf);
      dispatchItemId = parentMetric.id;
    }

    const dispatchParams = { id: dispatchItemId, statistics: {} };

    _.forEach(stats, (value) => {
      const index = _.findIndex(table.headers, (header) => {
        return _.endsWith(value.key, `.${_.get(header, 'name')}`);
      });
      if (index >= 0) {
        _.set(dispatchParams, value.key, table.data[0][index]);
      }
    });

    flux.dispatch('TREND_SET_STATISTICS', dispatchParams, PUSH_IGNORE);
  }
}

/**
 * Fetch a "normal" (unpaired) signal and return a promise with the results.
 *
 * @param seriesId - the series ID
 * @param {Object} range - Object container for range arguments
 * @param {Moment|Number} range.start - start of the range
 * @param {Moment|Number} range.end - end of the range
 * @param {Number} range.duration - duration of the time range in milliseconds
 * @param {Number} numPixels - the number or horizontal pixels avaliable for signal display
 * @param {Object} fragments - A formula fragment object where the keys are the names of unbound formula export function
 * variables and the values are constants identifying the formula fragments that should be used.
 * @param {String} cancellationGroup - the group used to cancel the requests
 * @returns {Promise} a promise that resolves with the fetched signal
 */
export function fetchOneSignal(seriesId: string, range, numPixels, fragments, cancellationGroup: string) {
  const laneWidth = `${getMSPerPixelWidth(range.duration, numPixels)}ms`;

  const downSampleFormula = sqTrendStore.buildDownsampleFormula(laneWidth, seriesId, null, getEnabledColumns());
  return computeSamples(
    _.omitBy(
      {
        id: seriesId,
        range,
        fragments: assignFragmentFormulas(fragments, laneWidth),
        formula: `$series${sqTrendStore.buildSummarizeFormula(seriesId)}${downSampleFormula}`,
        limit: numPixels * SPIKECATCHER_PER_PIXEL,
        cancellationGroup,
      },
      _.isUndefined,
    ),
  ).then((results) => ({
    valueUnitOfMeasure: results.valueUnitOfMeasure,
    samples: results.samples,
    table: results.table,
    timingInformation: results.timingInformation,
    meterInformation: results.meterInformation,
    warningLogs: results.warningLogs,
    warningCount: results.warningCount,
    interpolationMethod: results.interpolationMethod,
  }));
}

/**
 * Replaces a FORMULA_FRAGMENT_TYPE constant with the actual formula fragment that should be used by the backend
 * to evaluate the formula function.
 *
 * @param {Object} fragments - A formula fragment object where the keys are the names of unbound formula function
 * variables and the values are constants identifying the formula fragments that should be used.
 * @param {string} laneWidth - a string representation of the lane width in milliseconds
 * @returns {Object} A formula fragment object where the keys are the names of unbound formula function variables
 *   and the values are the corresponding formula fragments that are used to compute the value of the variable.
 */
export function assignFragmentFormulas(fragments, laneWidth): Record<string, string> {
  const result = _.transform(
    fragments,
    (result: Record<string, string>, value, key) => {
      if (value === FORMULA_FRAGMENT_TYPE.DISPLAY_RANGE) {
        result[key] = getCapsuleFormula(sqDurationStore.displayRange);
        // Also needs the laneWidth to be assigned so the backend can run spikeCatcher
        result.laneWidth = laneWidth; // expecting milliseconds
      }
    },
    {},
  );
  return !_.isEmpty(result) ? result : undefined;
}

/**
 * Create a condition formula based on the stitchTimes. The resulting condition contains
 * only the capsules that are being displayed in chain view
 *
 * @param {String} [seriesId] - the series ID that should be used for filtering stitchTimes in grouping mode
 */
export function createFormulaCondition(seriesId?) {
  // If capsule group mode is enabled then only signals that are paired to a condition are shown during the
  // conditions capsules. This requires filtering of the capsules by condition and then explicit loading of only
  // the grouped signals.
  let capsules;
  if (sqWorksheetStore.capsuleGroupMode && seriesId) {
    const conditionIds = _.keys(sqWorksheetStore.conditionToSeriesGrouping).filter((k) =>
      _.includes(sqWorksheetStore.conditionToSeriesGrouping[k], seriesId),
    );
    capsules = _.filter(sqTrendCapsuleStore.capsulesTimings, (c) => _.includes(conditionIds, c.isChildOf));
  }

  return conditionFormula(
    _.map(_.isEmpty(capsules) ? sqTrendCapsuleStore.stitchTimes : capsules, ({ start, end }) => ({
      startTime: start,
      endTime: moment.isMoment(end) ? end.valueOf() : end,
      properties: [],
    })),
  );
}

/**
 * Fetch a "normal" (unpaired) signal within a given condition and return a promise with the results.
 *
 * @param {String} seriesId - the series ID
 * @param {Number} numPixels - the number or horizontal pixels available for signal display
 * @param {String} cancellationGroup - the group used to cancel the requests
 * @returns {Promise} a promise that resolves with the fetched signal
 */
export function fetchOneSignalWithinCondition(seriesId, numPixels, cancellationGroup) {
  const range = sqDurationStore.displayRange;

  // Total all durations so we can figure out percentage of total chartWidth to be used for each period
  const durationTotal = _.sumBy(sqTrendCapsuleStore.stitchTimes, 'duration');
  const useablePixels = numPixels - PIXELS_PER_BREAK * sqTrendCapsuleStore.stitchBreaks.length;

  if (useablePixels === 0) {
    throw new Error('Not enough usable pixels - would have have divided by zero');
  }

  // We're in chain view, so reduce the number of pixels by the number used for the breaks. Note:
  // Nanoseconds are used to support very small capsules
  const laneWidth = `${
    NUMBER_CONVERSIONS.NANOSECONDS_PER_MILLISECOND * getMSPerPixelWidth(durationTotal, useablePixels)
  }ns`;
  const downSampleFormula = sqTrendStore.buildDownsampleFormula(
    laneWidth,
    seriesId,
    createFormulaCondition(seriesId),
    getEnabledColumns(),
  );
  return computeSamples({
    usePost: true, // Needed in case the condition formula is long
    id: seriesId,
    range,
    formula: `$series${sqTrendStore.buildSummarizeFormula(seriesId)}${downSampleFormula}`,
    limit: useablePixels * SPIKECATCHER_PER_PIXEL,
    cancellationGroup,
  });
}

/**
 * Fetch signal data for a pair of signals.
 *
 * @param shadedAreaLower - Lower bound item
 * @param shadedAreaUpper - Upper bound item
 * @param range - Display range
 * @param numPixels - the number or horizontal pixels available for signal display
 * @param cancellationGroup - the group used to cancel the requests
 * @returns a promise that resolves with the aligned signal data
 */
export function fetchPairedSignal(
  shadedAreaLower: { id: string; isSignal: boolean },
  shadedAreaUpper: { id: string; isSignal: boolean },
  range: RangeExport,
  numPixels: number,
  cancellationGroup: string,
) {
  const laneWidth = `${getMSPerPixelWidth(range.duration, numPixels)}ms`;
  return fetchSampleTableForSignalPair(
    `sampleTable(${[
      getCapsuleFormula({ start: range.start, end: range.end }),
      laneWidth,
      `$lower${!shadedAreaLower.isSignal ? '.toSignal()' : ''}`,
      `$upper${!shadedAreaUpper.isSignal ? '.toSignal()' : ''}`,
    ].join(', ')})`,
    shadedAreaLower.id,
    shadedAreaUpper.id,
    cancellationGroup,
  );
}

/**
 * Fetch signal data for a pair of signals within a condition.
 *
 * @param parentItemId - id of the parent condition
 * @param shadedAreaLower - Lower bound item
 * @param shadedAreaUpper - Upper bound item
 * @param range - Display range
 * @param numPixels - the number or horizontal pixels available for signal display
 * @param cancellationGroup - the group used to cancel the requests
 * @returns a promise that resolves with the aligned signal data
 */
export function fetchPairedSignalWithinCondition(
  parentItemId: string,
  shadedAreaLower: { id: string; isSignal: boolean },
  shadedAreaUpper: { id: string; isSignal: boolean },
  range: RangeExport,
  numPixels: number,
  cancellationGroup: string,
) {
  const laneWidth = `${getMSPerPixelWidth(range.duration, numPixels)}ms`;
  return fetchSampleTableForSignalPair(
    [
      `$condition = ${createFormulaCondition(parentItemId)}`,
      `sampleTable(${[
        getCapsuleFormula(sqDurationStore.displayRange),
        laneWidth,
        `$lower${!shadedAreaLower.isSignal ? '.toSignal()' : ''}.within($condition)`,
        `$upper${!shadedAreaUpper.isSignal ? '.toSignal()' : ''}.within($condition)`,
      ].join(', ')})`,
    ].join('\n'),
    shadedAreaLower.id,
    shadedAreaUpper.id,
    cancellationGroup,
  );
}

/**
 * Fetch a paired signal and return a promise with the results, in the same format as fetchOneSignal. This is needed
 * for shaded areas on the chart which are produced from two signals (boundaries in Metrics), but which Highcharts
 * treats as one series. In order to get a value for both the upper and lower signal the keys of both signals must
 * be aligned which is why the sampleTable operator is used. By default it will align the keys based on the sample
 * keys, but that can leave gaps at the edges since it does not include  the boundary values (CRAB-44921). To work
 * around this the $period parameter is set to the amount of time each pixel represents. This can result in it
 * returning more data than needed since it will always return at least one sample per pixel, but that is also what
 * spikecatcher can do for dense data and it solves the problem for these paired signals.
 */
async function fetchSampleTableForSignalPair(
  formula: string,
  lowerId: string,
  upperId: string,
  cancellationGroup: string,
) {
  const results = await computeTable({
    usePost: true, // Needed in case the formula is long
    formula,
    parameters: { lower: lowerId, upper: upperId },
    cancellationGroup,
  });

  return {
    valueUnitOfMeasure: results.headers[1].units,
    samples: _.map(results.data, ([key, lower, upper]) => ({
      key,
      lower,
      upper,
    })),
    timingInformation: results.timingInformation,
    meterInformation: results.meterInformation,
    warningLogs: results.warningLogs,
    warningCount: results.warningCount,
  };
}

/**
 * Fetches the data for the specified scalar. Computes a unique cancellation group name based on the
 * items so that if the another set of requests is made for the same items the first are canceled before fetching
 * the next batch. Calls to this function should still be debounced to ensure that there are not repeated calls
 * and cancels.
 *
 * @param {boolean} skipChildren - if true, no data is fetched for child items
 *
 * @return {Promise} A promise that resolves when all scalars have been fetched
 */
export function fetchAllScalars(skipChildren?) {
  return _.chain(sqTrendScalarStore.items)
    .reject((item) => isItemRedacted(item))
    .reject((item: any) => skipChildren && item.isChildOf)
    .map((item: any) => exposedForTesting.fetchScalar(item.id))
    .thru((promises) => Promise.all(promises))
    .value();
}

/**
 * Fetches the scalar data and then updates statistics.
 *
 * @param {String} itemId - The id of the scalar item to fetch.
 * @return {Promise} Promise that is resolved with the plot values for the scalar
 */
export function fetchScalar(itemId) {
  const item = sqTrendScalarStore.findItem(itemId);
  const cancellationGroup = `fetchScalar${CANCELLATION_GROUP_GUID_SEPARATOR}${itemId}`;

  if (
    isHidden({
      item,
    })
  ) {
    flux.dispatch('TREND_SET_DATA_STATUS_HIDDEN_FROM_TREND', { id: item.id }, PUSH_IGNORE);
    return Promise.resolve();
  }

  flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id: item.id }, PUSH_IGNORE);
  cancelGroup(cancellationGroup, true);

  let scalarRequest;
  if (item.shadedAreaUpper && item.shadedAreaLower) {
    // There are two paired scalars (scalar boundaries). Fetch them both
    scalarRequest = Promise.all([
      computeScalar({
        id: item.shadedAreaLower.id,
        cancellationGroup,
      }),
      computeScalar({
        id: item.shadedAreaUpper.id,
        cancellationGroup,
      }),
    ]).then(([lowerResult, upperResult]) => {
      return _.assign({}, _.omit(lowerResult, ['value']), {
        lower: lowerResult.value,
        upper: upperResult.value,
      });
    });
  } else {
    scalarRequest = computeScalar({
      id: getItemId(item),
      cancellationGroup,
    });
  }
  return scalarRequest
    .then((results) => {
      const { start, end } = sqDurationStore.displayRange;
      const payload = _.assign(
        {
          id: item.id,
          start: start.valueOf(),
          end: end.valueOf(),
        },
        _.pick(results, [
          'value',
          'lower',
          'upper',
          'warningCount',
          'warningLogs',
          'timingInformation',
          'meterInformation',
        ]),
      );

      flux.dispatch('TREND_SCALAR_RESULTS_SUCCESS', payload, PUSH_IGNORE);
      updateLaneDisplay();
      return payload;
    })
    .catch(_.partial(exposedForTesting.catchItemDataFailure, item.id, cancellationGroup));
}

/**
 * Fetches the capsules for all timebar conditions.
 */
export function fetchAllTimebarCapsules() {
  // Clear all to ensure regions for inactive conditions are removed
  flux.dispatch('TIMEBAR_CLEAR_REGIONS', {}, PUSH_IGNORE);
  return Promise.all(
    _.map(
      getAllItems({
        excludeEditingCondition: true,
        workingSelection: true,
        excludeDataStatus: [ITEM_DATA_STATUS.REDACTED],
        itemTypes: [ITEM_TYPES.CONDITION],
      }),
      (item) => exposedForTesting.fetchTimebarCapsules(getItemId(item)),
    ),
  );
}

/**
 * Fetches the capsules, in an aggregated form, for the timebar and dispatches the results.
 *
 * @param {String} id - The id of the condition for which to fetch capsules
 * @returns {Promise} A promise that resolves when the results have been fetched.
 */
export function fetchTimebarCapsules(id) {
  if (!sqTrendStore.capsulePreview) {
    flux.dispatch('TIMEBAR_SET_REGIONS_FOR_CONDITION', { id, regions: [] }, PUSH_IGNORE);
    return Promise.resolve();
  }
  const cancellationGroup = `timebarCapsules${CANCELLATION_GROUP_GUID_SEPARATOR}${id}`;
  const item = sqTrendConditionStore.findItem(id);
  const range = sqDurationStore.investigateRange;
  const width = document.querySelector('#timebar')?.clientWidth;

  if (_.isNil(width) || width <= 0) {
    return Promise.resolve();
  }

  const buckets = Math.floor(width / 2);
  const bucketWidthArg = `${range.duration.asMilliseconds() / buckets}ms`;
  return cancelGroup(cancellationGroup)
    .then(() =>
      calculate({
        item,
        range,
        bucketWidthArg,
        cancellationGroup,
      }),
    )
    .then((regions) => flux.dispatch('TIMEBAR_SET_REGIONS_FOR_CONDITION', { id, regions }, PUSH_IGNORE))
    .catch((e) => {
      if (!isCanceled(e)) {
        logError(formatMessage`Error with fetchTimebarCapsules: ${e}`);
      }
      flux.dispatch('TIMEBAR_SET_REGIONS_FOR_CONDITION', { id, regions: [] }, PUSH_IGNORE);
    });
}

function getCapsuleCustomPropertyValuesAndUOMs(
  capsule: CapsuleFormulaTableRow,
  capsuleHeaders: TableColumnOutputV1[],
  customColumnKeys: string[],
): CustomPropertyValuesAndUOMs & { statistics?: AnyProperty<string | number> } {
  return _.chain(customColumnKeys)
    .reduce(
      (allProps, customColumnKey) => {
        const [_ignored, topKey, subKey] = customColumnKey.match(/^([^.]+)\.(.+)$/) ?? [];
        const value = capsule[customColumnKey];
        if (_.isNil(value) || !topKey) {
          return allProps;
        }

        return topKey === 'statistics'
          ? _.merge(allProps, { statistics: { [subKey]: value } })
          : _.merge(allProps, {
              properties: { [subKey]: value },
              propertiesUOM: { [subKey]: _.find(capsuleHeaders, { name: subKey })?.units },
            });
      },
      { properties: {}, propertiesUOM: {}, statistics: {} },
    )
    .omitBy(_.isEmpty)
    .value();
}

/**
 * Fetches the capsules for a list of conditions and then adds the capsules to the store. Data for each capsule
 * is provided either through one of the predefined TREND_COLUMNS or a custom column definition.
 *
 * Adds table capsules to chart if view is capsule time
 *
 * @param {Object} options - Options for fetching
 * @param {boolean} options.fetchFailed - True to force it to also fetch FAILED conditions
 *
 * @returns {Promise} Resolves with the capsule data
 */
export function fetchTableAndChartCapsules(options = { fetchFailed: false }): Promise<void> {
  if (
    !sqWorkbenchStore.stateParams.worksheetId ||
    _.includes([WORKSHEET_VIEW.TABLE, WORKSHEET_VIEW.TREEMAP], sqWorksheetStore.view.key) ||
    sqWorkbookStore.isReportBinder
  ) {
    return Promise.resolve();
  }

  const cancellationGroup = 'tableCapsules';
  const conditionIds = _.chain(
    getAllItems({
      // Ensure that failing conditions do not fail the whole request
      excludeDataStatus: options.fetchFailed
        ? [ITEM_DATA_STATUS.REDACTED] // Only retry REDACTED items by refreshing the page
        : [ITEM_DATA_STATUS.REDACTED, ITEM_DATA_STATUS.FAILURE, ITEM_DATA_STATUS.CANCELED],
      workingSelection: true,
      excludeEditingCondition: true,
      itemTypes: [ITEM_TYPES.CONDITION],
    }),
  )
    // This handles the case that, in dimming mode, no conditions are selected, but
    // another item is selected, preventing the condition from being shown. However,
    // chain view and capsule time don't make sense without conditions so in those views
    // populate the table with all the conditions even though none are selected.
    .reject(
      (item) =>
        sqTrendStore.view === TREND_VIEWS.CALENDAR &&
        isHidden({
          item,
        }),
    )
    .map(getItemId)
    .value();

  const range = sqDurationStore.displayRange;
  const initialSort = sqTrendStore.getPanelSort(TREND_PANELS.CAPSULES);
  const sort: TableSortParams = {
    sortAsc: initialSort.sortAsc,
    sortBy: initialSort.sortBy,
    orderedAdditionalSortPairs: [
      {
        sortBy: 'conditionId',
        sortAsc: true,
      },
    ],
  };
  const offset = sqTrendStore.capsulePanelOffset;

  if (_.isEmpty(conditionIds)) {
    // No conditions would have been fetched, clear the table
    flux.dispatch(
      'TREND_ADD_CAPSULES',
      {
        capsules: [],
        numPixels: Math.min(chartWidth, getMaxSeriesPixels()),
      },
      PUSH_IGNORE,
    );
    // Clear the Capsule pane loading spinner
    flux.dispatch('TREND_SET_CAPSULE_PANEL_IS_LOADING', { isLoading: false }, PUSH_IGNORE);
    return Promise.resolve();
  }
  const { allDecoratedPropertyColumns, allDecoratedStatColumns, customColumnKeys } = getPropertyAndStatisticsColumns();

  flux.dispatch('TREND_SET_CAPSULE_PANEL_IS_LOADING', { isLoading: true }, PUSH_IGNORE);
  return cancelGroup(cancellationGroup)
    .then(() =>
      computeCapsuleTable({
        columns: {
          propertyColumns: allDecoratedPropertyColumns,
          statColumns: allDecoratedStatColumns,
        },
        range: { start: range.start.valueOf(), end: range.end.valueOf() },
        buildAdditionalFormula: getBuildAdditionalFormula(allDecoratedPropertyColumns, allDecoratedStatColumns, true),
        itemIds: conditionIds,
        sortParams: sort,
        offset,
        limit: CAPSULES_PER_PAGE,
        cancellationGroup,
      }),
    )
    .then((results) => {
      const capsulesToAdd = [];
      flux.dispatch('TREND_SET_CAPSULE_PANEL_HAS_NEXT', { hasNext: results.data?.hasNextPage }, PUSH_IGNORE);
      _.forEach(results.data?.table, (capsule) => {
        const condition = sqTrendConditionStore.findItem(capsule.conditionId);
        if (condition) {
          const capsuleToPush = {
            id: capsule.capsuleId,
            isChildOf: condition.id,
            childType: ITEM_CHILDREN_TYPES.CAPSULE,
            capsuleSetName: condition.name,
            capsuleSetId: condition.id,
            color: condition.color,
            isUncertain: capsule.isUncertain,
            cursorKey: capsule.cursorKey,
            isReferenceCapsule: capsule.isReferenceCapsule,
            startTime: _.get(capsule, 'startTime', range.start),
            endTime: _.get(capsule, 'endTime', range.end),
            notFullyVisible: !isCapsuleFullyVisible(capsule.startTime, capsule.endTime),
            similarity: capsule.similarity,
          };

          _.assign(
            capsuleToPush,
            exposedForTesting.getCapsuleCustomPropertyValuesAndUOMs(capsule, results.data?.headers, customColumnKeys),
          );

          _.forEach(CAPSULE_PANEL_LOOKUP_COLUMNS, (column) => {
            if (sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, column.key)) {
              capsuleToPush[column.accessor] = condition[column.accessor];
            }
          });

          // If the display range has not changed (as a result of a growing capsule) - we need to fetch the chart
          // capsules
          capsulesToAdd.push(capsuleToPush);
        }
      });

      flux.dispatch(
        'TREND_ADD_CAPSULES',
        {
          capsules: capsulesToAdd,
          numPixels: Math.min(chartWidth, getMaxSeriesPixels()),
        },
        PUSH_IGNORE,
      );

      const headers = results.data.headers;
      _.forEach(CAPSULE_PANEL_LOOKUP_COLUMNS, (column: PropertyColumn) => {
        if (sqTrendStore.isColumnEnabled(TREND_PANELS.CAPSULES, column.key)) {
          headers.push({ name: column.title, type: column.style });
        }
      });

      flux.dispatch('TREND_SET_PROPERTY_COLUMNS', { headers }, PUSH_IGNORE);

      exposedForTesting.removeItems(
        _.reject(sqTrendSeriesStore.capsuleSeries, (series: any) => sqTrendCapsuleStore.findItem(series.capsuleId)),
        PUSH_IGNORE,
        false,
      );

      setTrendColorMode();

      flux.dispatch('TREND_SET_CAPSULE_PANEL_IS_LOADING', { isLoading: false }, PUSH_IGNORE);
    })
    .catch((e) => {
      if (!isCanceled(e)) {
        flux.dispatch('TREND_SET_CAPSULE_PANEL_IS_LOADING', { isLoading: false }, PUSH_IGNORE);
        flux.dispatch(
          'TREND_ADD_CAPSULES',
          {
            capsules: [],
            numPixels: Math.min(chartWidth, getMaxSeriesPixels()),
          },
          PUSH_IGNORE,
        );

        if (isBackendRowsLimitError(e)) {
          errorToast({
            messageKey: i18next.t('TREND_ROWS_LIMIT_ERROR'),
          });
        } else {
          errorToast({
            httpResponseOrError: e,
          });
        }
      }
    })
    .finally(() => {
      exposedForTesting.addCapsuleTimeSegments(true);
      exposedForTesting.createStitchDetails().catch((err) => {});
    });
}

/**
 * Fetches the series that are the results of a live preview (if one is in progress).
 * This is called when scrolling.
 *
 * @returns {Promise} that resolves once the results have been added to the chart
 */
export function fetchPreviewSeries() {
  const previewSeriesDef = sqTrendSeriesStore.previewSeriesDefinition;
  if (!_.isEmpty(previewSeriesDef)) {
    return exposedForTesting.generatePreviewSeries(
      previewSeriesDef.formula,
      previewSeriesDef.parameters,
      previewSeriesDef.id,
      previewSeriesDef.color,
    );
  } else {
    return Promise.resolve();
  }
}

/**
 * Fetches capsules for specified capsule sets or all capsule sets in the display range and dispatches them to be
 * displayed on the chart.
 *
 * @param {Object[]} items - Optional array of items currently in the chart that need to be fetched
 * @return {Promise} Resolves when all the capsules have been dispatched.
 */
export function fetchChartCapsules(items?: any[]): Promise<void> {
  const cancellationGroupPrefix = 'chartCapsules';
  const fetchItems = items
    ? _.map(items, (item) => {
        return sqTrendConditionStore.findItem(item.id);
      })
    : sqTrendConditionStore.items;

  if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND) {
    return Promise.resolve();
  }

  return _.chain(fetchItems)
    .reject((item) => isItemRedacted(item))
    .map((capsuleSet: any) => {
      const conditionId = getItemId(capsuleSet);
      const cancellationGroup = cancellationGroupPrefix + CANCELLATION_GROUP_GUID_SEPARATOR + conditionId;
      // Chain view and capsule time requires chart capsules to be fetched even though the conditions
      // themselves might not be shown
      if (
        sqTrendStore.view === TREND_VIEWS.CALENDAR &&
        isHidden({
          item: capsuleSet,
        })
      ) {
        flux.dispatch('TREND_SET_DATA_STATUS_HIDDEN_FROM_TREND', { id: capsuleSet.id }, PUSH_IGNORE);
        // Clears the data from the hidden capsule chartItems
        return Promise.resolve({ id: capsuleSet.id, capsules: [] });
      }
      flux.dispatch('TREND_SET_DATA_STATUS_LOADING', _.pick(capsuleSet, ['id']), PUSH_IGNORE);
      const { allDecoratedStatColumns, allDecoratedPropertyColumns } = getPropertyAndStatisticsColumns();

      return cancelGroup(cancellationGroup, true)
        .then(() =>
          computeCapsulesWithLimit(
            {
              id: conditionId,
              isUnbounded: capsuleSet.isUnbounded,
              range: sqDurationStore.displayRange,
              limit: CHART_CAPSULES_LIMIT,
              cancellationGroup,
            },
            allDecoratedPropertyColumns,
            allDecoratedStatColumns,
          ),
        )
        .then((result) => {
          exposedForTesting.fetchStatistics(capsuleSet);
          flux.dispatch(
            'TREND_SET_DATA_STATUS_PRESENT',
            _.assign(
              { id: capsuleSet.id },
              _.pick(result, ['warningCount', 'warningLogs', 'timingInformation', 'meterInformation']),
            ),
            PUSH_IGNORE,
          );

          return _.assign(
            { capsules: result.capsules },
            _.pick(capsuleSet, ['id', 'name', 'color', 'selected', 'lane', 'lineWidth', 'overlay']),
          );
        })
        .catch((error) => {
          exposedForTesting.catchItemDataFailure(capsuleSet.id, cancellationGroup, error);
          // Prevents all the chart capsules from disappearing when just one is having problems
          return Promise.resolve({ id: capsuleSet.id, capsules: [] });
        });
    })
    .thru((promises) => Promise.all(promises))
    .value()
    .then((capsuleSets) => {
      flux.dispatch(
        items ? 'TREND_ADD_CHART_CAPSULES' : 'TREND_SET_CHART_CAPSULES',
        {
          capsuleSets,
          numPixels: Math.min(chartWidth, getMaxSeriesPixels()),
        },
        PUSH_IGNORE,
      );
    })
    .catch((err) => {});
}

/**
 * Dispatches a command to set properties on a trend item
 *
 * @param {String} id - The id of the item
 * @param {Object} props - The properties to set
 * @param {String} [pushMode] - One of the PUSH constants
 */
export function setTrendItemProps(id, props, pushMode?) {
  flux.dispatch('TREND_SET_PROPERTIES', _.assign({ id }, props), pushMode);
}

function getLineWidth(sampleDisplayOption: SAMPLE_OPTIONS, currentLineWidth: number) {
  const lineWidths = sampleDisplayOption === SAMPLE_OPTIONS.BAR ? BAR_CHART_LINE_WIDTHS : LINE_WIDTHS;

  return _.includes(lineWidths, currentLineWidth) ? currentLineWidth : 1;
}

/**
 * Updates the target for custom labels
 *
 * @param targetUpdates - an array of current and new target(s) for one or more custom labels
 */
export function updateCustomLabelTargets(targetUpdates: CustomLabelTargetUpdate[], pushOption?: PushOption) {
  flux.dispatch('TREND_UPDATE_CUSTOM_LABEL_TARGETS', { targetUpdates }, pushOption);
}

/**
 * Dispatches a command to set configuration properties on a trend item
 *
 * @param {Object[]} itemsProperties - items to update
 * @param {String} itemsProperties.id - The id of the item
 * @param {Object} itemsProperties.* - The properties to set
 * @param {PushOption} [pushMode] - One of the PUSH constants
 */
export function setCustomizationProps(itemsProperties: WithProperty<{ id: string }>[], pushMode?: PushOption): void {
  const items = _.map(itemsProperties, (payload) => {
    const item = findItemIn(getTrendStores(), payload.id);

    // Reset the lineWidth to a valid option when changing sampleDisplayOption
    const sampleDisplayOption = payload.sampleDisplayOption;
    if (sampleDisplayOption) {
      let currentItem = item;
      if (_.get(item, 'itemType') === ITEM_TYPES.METRIC) {
        currentItem = _.find(findChildrenIn(getTrendStores(), payload.id), [
          'childType',
          ITEM_CHILDREN_TYPES.METRIC_DISPLAY,
        ]);
      }
      const lineWidth = getLineWidth(sampleDisplayOption, _.get(currentItem, 'lineWidth', 1));

      payload = {
        ...payload,
        lineWidth,
      };
    }

    if (payload.lane !== undefined && payload.overlay === undefined) {
      payload.overlay = undefined;
    }

    // make sure the chart capsules are updated to reflect the new lane/lineWidth
    if (item?.itemType === ITEM_TYPES.CONDITION) {
      const props = _.pick(payload, ['id', 'lineWidth', 'lane', 'overlay']);
      flux.dispatch('TREND_CUSTOMIZE_CHART_CAPSULES', props, PUSH_IGNORE);
    }

    payload.itemType = item?.itemType;

    return payload;
  });

  flux.dispatch('TREND_SET_CUSTOMIZATIONS', { items }, pushMode);
}

export function setCanCapsulesExpand(canCapsulesExpand: boolean) {
  flux.dispatch('TREND_SET_CAN_CAPSULES_EXPAND', { canCapsulesExpand });
  flux.dispatch('TREND_RECOMPUTE_CHART_CAPSULES', null, PUSH_IGNORE);
}

/**
 * Sets the scopedTo property of an item so it is available globally
 *
 * @param id - the item ID
 *
 * @returns {Promise} a promise that resolves when the scope has been set and item properties have been fetched
 */
export function setTrendGlobalScope(id: string): Promise<void> {
  return sqItemsApi
    .setScope({ id })
    .then(() => {
      exposedForTesting.fetchItemProps(id);
      emitPermissions(sqWorkbenchStore.stateParams.workbookId, sqWorkbenchStore.stateParams.worksheetId, id);
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

/**
 * Sets the offset for the capsule panel's list of capsules.
 *
 * @param offset - The offset in the list of capsules
 *
 * @returns a promise that resolves when table capsules have been fetched using the new offset
 */
export function setCapsulePanelOffset(offset: number): Promise<void> {
  flux.dispatch('TREND_SET_CAPSULE_PANEL_OFFSET', { offset });

  return exposedForTesting.fetchTableAndChartCapsules();
}

/**
 * Resets the offset for the capsule panel's list of capsules to zero.
 *
 * @returns a promise that resolves when table capsules have been fetched using the new offset
 */
export function resetCapsulePanelOffset(): Promise<void> {
  flux.dispatch('TREND_RESET_CAPSULE_PANEL_OFFSET');

  return exposedForTesting.fetchTableAndChartCapsules();
}

/**
 * Refreshes the charts and trends in a Workbench Analysis worksheet.
 */
function refreshViews(): void {
  updateLaneDisplay();
  fetchTreemap();
  refreshScatterPlotView();
}

/**
 * Sets the color of an item.
 *
 * @param {String} id - The id of the item to update
 * @param {String} color - The color for the item
 * @param {PushOption} [pushMode] - One of the PUSH constants
 */
export function setTrendItemColor(id: string, color: string | undefined, pushMode?: PushOption): void {
  const item = findItemIn(getTrendStores(), id);
  flux.dispatch('TREND_SET_COLOR', { id, color }, pushMode);

  refreshViews();

  if (item?.childType !== ITEM_CHILDREN_TYPES.SERIES_FROM_CAPSULE) {
    exposedForTesting.setCapsuleTimeColorMode();
  }
}

/**
 * Toggles the dimming of data outside of capsules in capsule time, hidden if false
 */
export function toggleDimDataOutsideCapsules() {
  flux.dispatch('TREND_TOGGLE_DIM_DATA_OUTSIDE_CAPSULES');
  exposedForTesting.addCapsuleTimeSegments(true);
}

/**
 * Toggles the visibility of unselected items
 */
export function toggleHideUnselectedItems() {
  flux.dispatch('TREND_TOGGLE_HIDE_UNSELECTED_ITEMS');
  if (sqTrendStore.hideUnselectedItems) {
    exposedForTesting.removeUnselectedSeriesFromCapsules();

    const items = getAllItems({});
    if (items.some((item) => item.selected)) {
      clearStatisticsForUnselectedItems(items);
    }
  } else {
    exposedForTesting.fetchHiddenTrendData();
    exposedForTesting.fetchTableAndChartCapsules();
  }
  exposedForTesting.fetchAllStatistics(true);
  updateLaneDisplay();
}

/**
 * Toggles the visibility of uncertainty indicators
 */
export function toggleHideUncertainty() {
  flux.dispatch('TREND_TOGGLE_HIDE_UNCERTAINTY');
}

/**
 * Sets visibility of uncertainty indicators
 *
 * @param hideUncertainty - True if uncertainty indicators should be hidden
 */
export function setHideUncertainty(hideUncertainty: boolean) {
  flux.dispatch('TREND_SET_HIDE_UNCERTAINTY', hideUncertainty);
}

function getItemCustomPropertyValuesAndUOMs(fetchedItem: ItemOutputV1): CustomPropertyValuesAndUOMs {
  return _.chain(sqTrendStore.propertyColumns(TREND_PANELS.SERIES))
    .filter(({ key }) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, key))
    .reduce(
      (allProps, { propertyName }) => {
        const property = _.find(fetchedItem.properties, { name: propertyName });
        if (!property?.value) {
          return allProps;
        }

        return _.merge(allProps, {
          properties: { [propertyName]: property?.value },
          propertiesUOM: { [propertyName]: property?.unitOfMeasure },
        });
      },
      { properties: {}, propertiesUOM: {} },
    )
    .omitBy(_.isEmpty)
    .value();
}

/**
 * Fetches additional static properties for an item. This includes properties that are always fetched, such as
 * name, and others that are enabled by the user and stored in the sqTrendStore. Additionally, the assets
 * associated with an item. For a stored item, it will be the asset that is the parent of the item. For a
 * calculated item, it will be the parent asset of the item and the parent assets of all formula arguments used to
 * compute the calculated item.
 *
 * @param itemId - the ID of the item to fetch properties for
 * @param skipDependencies - skip property dependencies. Useful if these have already been loaded
 * @param fetchedProperties - Properties that have been fetched already, as a batch, that can be used in place of
 * individual calls.
 *
 * @return Promise that will resolve when all the information has been fetched
 */
export function fetchItemProps(
  itemId: string,
  skipDependencies = false,
  fetchedProperties: { contextConditions?: ContextConditionsMap } = {},
) {
  const item = findItemIn(getTrendStores(), itemId);
  if (!isInWorkbookRouteAndWorkbookLoaded() || !item || item.isChildOf) {
    return Promise.resolve([]);
  }
  const cancellationGroup = `itemProps-${item.id}`;

  // Command the last fetch request to update so plugins can recognize when a fetch request was made for an item
  flux.dispatch('TREND_UPDATE_LAST_FETCH_REQUEST', { id: item.id });

  const chartCapsuleColumn = sqTrendStore.enabledColumns(TREND_PANELS.CHART_CAPSULES);
  const areAssetLaneLabelsOff = sqTrendStore.labelDisplayConfiguration.asset === LABEL_LOCATIONS.OFF;

  const skipGetDependencies =
    isPresentationWorkbookMode() &&
    sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREEMAP && // treemap needs dependencies
    (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TREND ||
      // if we are in a trend, we can still skip dependencies if these labels are off
      (areAssetLaneLabelsOff && !chartCapsuleColumn?.asset));

  // Fetching dependencies can be slow and we do not want other aspects of the UI (e.g. edit icon) to have to wait
  // for it to finish before rendering.
  const dependenciesPromise = (
    skipGetDependencies
      ? Promise.resolve({ assets: [] })
      : getDependencies({ id: item.id }).catch((response) =>
          exposedForTesting.catchItemDataFailure(item.id, cancellationGroup, response, []),
        )
  ).then(({ assets }) => exposedForTesting.setTrendItemProps(item.id, { assets, includeChildren: true }, PUSH_IGNORE));

  return Promise.all([
    // Always retrieve the item. Calendar and Chain views need the Scorecard and Treemap store
    // rendering information as properties on the item.
    sqItemsApi
      .getItemAndAllProperties({ id: item.id }, { cancellationGroup })
      .then(({ data }) => data)
      .catch((response) =>
        exposedForTesting.catchItemDataFailure(item.id, cancellationGroup, response, {}),
      ) as Promise<ItemOutputV1>,
    (item.itemType === ITEM_TYPES.METRIC
      ? fetchMetric(item.id).then((result) => _.get(result, 'data'))
      : Promise.resolve()) as Promise<ThresholdMetricOutputV1>,
    _.isNil(fetchedProperties.contextConditions) &&
    (item.itemType === ITEM_TYPES.SERIES || item.itemType === ITEM_TYPES.CONDITION)
      ? sqItemsApi
          .getContextCondition({ id: item.id })
          .then(({ data }) => data.id)
          // If it doesn't exist, that's a valid state
          .catch(() => undefined)
      : Promise.resolve(fetchedProperties.contextConditions?.[item.id]),
  ])
    .then(([fetchedItem, fetchedMetric, contextConditionId]) => {
      const dispatchParams: AnyProperty = {};
      dispatchParams.name = fetchedItem.name;

      // We need to retrieve the description every time so that we can add it as an axis/lane label regardless if the
      // column is enabled or not
      dispatchParams.description = fetchedItem.description;

      if (sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, DATASOURCE_NAME)) {
        dispatchParams.datasource = { name: fetchedItem.datasource?.name };
      }

      // set the formatOptions
      const numberFormat =
        _.find(fetchedItem.properties, ['name', SeeqNames.Properties.NumberFormat]) ||
        _.find(fetchedItem.properties, ['name', SeeqNames.Properties.SourceNumberFormat]);
      const stringFormat =
        _.find(fetchedItem.properties, ['name', SeeqNames.Properties.StringFormat]) ||
        _.find(fetchedItem.properties, ['name', SeeqNames.Properties.SourceStringFormat]);
      dispatchParams.formatOptions = {
        format: _.get(numberFormat, 'value'),
        stringFormat: _.get(stringFormat, 'value'),
      } as FormatOptions;

      // set calculated properties
      dispatchParams.calculationType = getToolType(fetchedItem);

      // Need to know the source id of a swap in a few places such as sq-select-item
      const swapSourceProp = _.find(fetchedItem.properties, ['name', SeeqNames.Properties.SwapSourceId]) as any;
      dispatchParams.swapSourceId = swapSourceProp ? _.toUpper(swapSourceProp.value) : null; // uppercase for
      // consistency with id

      dispatchParams.isArchived = !!fetchedItem.isArchived;

      const uomProp = _.find(
        fetchedItem.properties,
        (prop) => prop.name === SeeqNames.Properties.ValueUom || prop.name === SeeqNames.Properties.Uom,
      ) as any;
      if (uomProp) {
        dispatchParams.valueUnitOfMeasure = uomProp.value;
      }
      const sourceUomProp = _.find(fetchedItem.properties, ['name', SeeqNames.Properties.SourceValueUom]) as any;
      if (sourceUomProp) {
        dispatchParams.sourceValueUnitOfMeasure = sourceUomProp.value;
      }
      const displayUnitProp = _.find(fetchedItem.properties, ['name', SeeqNames.Properties.DisplayUnit]) as any;
      dispatchParams.displayUnit = displayUnitProp?.value ?? null;

      _.assign(dispatchParams, exposedForTesting.getItemCustomPropertyValuesAndUOMs(fetchedItem));

      if (fetchedItem.scopedTo) {
        dispatchParams.scopedTo = fetchedItem.scopedTo;
      }

      if (fetchedItem.effectivePermissions) {
        dispatchParams.effectivePermissions = fetchedItem.effectivePermissions;
      }

      if (item.itemType === ITEM_TYPES.CONDITION) {
        dispatchParams.isUnbounded = !_.some(fetchedItem.properties, ['name', SeeqNames.Properties.MaximumDuration]);
      }

      if (contextConditionId) {
        dispatchParams.contextConditionId = contextConditionId;
      }

      exposedForTesting.setTrendItemProps(item.id, _.omitBy(dispatchParams, _.isUndefined), PUSH_IGNORE);
      if (!dispatchParams.calculationType) {
        exposedForTesting.setTrendItemProps(item.id, { calculationType: undefined }, PUSH_IGNORE);
      }

      addLegacyBoundaries(item, _.get(item, 'displayedAncillaryItemIds', []));

      if (fetchedMetric) {
        exposedForTesting.addMetricChildren(item.id, fetchedMetric, skipDependencies);
      }

      if (item.itemType === ITEM_TYPES.SERIES) {
        exposedForTesting.addCapsuleTimeSegments(true, [item]);
      }
    })
    .then(() => dependenciesPromise) as Promise<any>;
}

/**
 * Fetches the metric item with data status updates. Dispatches data status updates if it is not in table builder
 * view because that view takes care of setting item data status.
 *
 * @param id - the metric ID
 * @returns the metric result
 */
export function fetchMetric(id: string): Promise<ThresholdMetricOutputV1> {
  const cancellationGroup = `trendFetchMetric${CANCELLATION_GROUP_GUID_SEPARATOR}${id}`;
  ifNotTableBuilderView(() => flux.dispatch('TREND_SET_DATA_STATUS_LOADING', { id }, PUSH_IGNORE));
  return cancelGroup(cancellationGroup)
    .then(() => sqMetricsApi.getMetric({ id }, { cancellationGroup }))
    .then((result) => {
      ifNotTableBuilderView(() => flux.dispatch('TREND_SET_DATA_STATUS_PRESENT', { id }));
      return result;
    })
    .catch((e) => exposedForTesting.catchItemDataFailure(id, cancellationGroup, e));

  function ifNotTableBuilderView(action) {
    if (sqWorksheetStore.view.key !== WORKSHEET_VIEW.TABLE) {
      action();
    }
  }
}

/**
 * Legacy code to support old ancillary boundaries that have been converted to threshold metrics. Looks through all
 * metrics that have the parent as a measured item and which have at least one of the boundaries as a threshold.
 * If so, then adds those thresholds with the old boundary look.
 *
 * @param parent - The parent item, from the sqTrendSeriesStore
 * @param boundaryIds - The ids of items that were used as the boundary thresholds
 */
export function addLegacyBoundaries(parent: any, boundaryIds: string[]) {
  if (!_.isEmpty(boundaryIds)) {
    sqItemsApi
      .getItemUsages({ id: parent.id, scope: [sqWorkbenchStore.stateParams.workbookId] })
      .then(({ data: { items } }) => {
        _.chain(items)
          .filter({ type: SeeqNames.Types.ThresholdMetric })
          .forEach(({ id }) =>
            sqMetricsApi.getMetric({ id }).then(({ data: metric }) => {
              if (
                metric.measuredItem.id === parent.id &&
                _.some(metric.thresholds, (threshold) => _.includes(boundaryIds, threshold.item?.id))
              ) {
                const lower = _.find(metric.thresholds, (threshold) => threshold.priority.level < 0)?.item;
                const upper = _.find(metric.thresholds, (threshold) => threshold.priority.level > 0)?.item;
                const showMigrationToast = () => {
                  warnToast({
                    messageKey: 'BOUNDARIES_MIGRATED',
                    messageParams: { name: parent.name },
                    buttonLabelKey: 'BOUNDARIES_VIEW',
                    buttonIcon: 'fc fc-metric',
                    buttonIconStyle: 'dark-gray',
                    buttonVariant: 'outline',
                    buttonAction: () =>
                      fetchItemUsages(parent, 'main', [sqWorkbenchStore.stateParams.workbookId], true).then(() => {
                        tabsetChangeTab('sidebar', 'search');
                      }),
                  });
                };

                if (lower && upper) {
                  showMigrationToast();
                  addShadedArea(
                    {
                      parentId: parent.id,
                      childType: ITEM_CHILDREN_TYPES.ANCILLARY,
                      baseItem: metric,
                      lower,
                      upper,
                      props: {
                        axisVisibility: false,
                        fillOpacity: 0.2,
                        lineWidth: 0,
                      },
                    },
                    PUSH_IGNORE,
                  );
                } else if (lower || upper) {
                  showMigrationToast();
                  addChildItem(
                    parent.id,
                    ITEM_CHILDREN_TYPES.ANCILLARY,
                    lower || upper,
                    {
                      id: `${parent.id}_${metric.id}`,
                      dashStyle: DASH_STYLES.DASH,
                      axisVisibility: false,
                    },
                    PUSH_IGNORE,
                  );
                }
              }
            }),
          )
          .value();
      })
      .catch(_.noop);
  }
}

/**
 * @see addShadedArea
 */
type AddShadedAreaParams = {
  parentId: string;
  childType: string;
  baseItem: any;
  lower?: any;
  upper?: any;
  props?: any;
};

/**
 * Adds a shaded area to the trend stores. A shaded areas are special items in the trendSeries and trendScalar
 * stores that highcharts will draw solid filled areas for on the chart. A shaded area has `shadedArea*`
 * properties that determine how it looks and behaves on the trend and how data should be requested.
 *
 * There are a few classes of shaded areas:
 *  - shading from a signal or scalar to the edge of the lane
 *    - the item is requested as normal, but the chart will draw up or down to Infinity or -Infinity
 *    - `shadedAreaDirection` property determines the direction
 *  - shading between two signals or a signal and a scalar
 *    - the item is requested by pairing the upper and lower ids and the data array will contain an extra y value
 *    - stored in the trendSeries store and is requested as the display range changes
 *    - `shadedAreaLower` and `shadedAreaUpper` contain the ids and whether `toScalar` needs to be used on the id
 *  - shading between two scalars
 *    - the item is requested by requesting both the values and constructing a matching data array
 *    - stored in the trendScalar store and not requested as the display range changes
 *    - `shadedAreaLower` and `shadedAreaUpper` contain the ids of the scalars
 *
 * For shading between two items `shadedAreaCursors` determines if both the top and the bottom of the shaded
 * region should produce cursors that show the value on the trend. For example, if you are trying to stack shaded
 * areas for an effect you want to avoid having the same value show up twice.
 *
 * @param {string} parentId - this id will be used as the parent for the new child item
 * @param {string} childType - one of ITEM_CHILDREN_TYPES, shaded areas can only be child items
 * @param {Object} baseItem - the item that is the "focus" of the shaded area; the id is used as the id of the item
 * @param {Object} [lower] - the item for the lower edge of the shaded area or absent to indicate shading to bottom
 * @param {Object} [upper] - the item for the upper edge of the shaded area or absent to indicate shading to top
 * @param {Object} [props] - extra properties to set on the item
 * @param {string} [option] - argument to pass to relevant flux.dispatch calls
 * @param skipDependencies - Will signal whether to skip the child's dependencies
 */
export function addShadedArea(
  { parentId, childType, baseItem, lower, upper, props }: AddShadedAreaParams,
  option?,
  skipDependencies = false,
) {
  const lowerType = lower && getItemType(lower);
  const upperType = upper && getItemType(upper);
  const { id, name, type } = baseItem;

  if ((lower && !_.includes(SHADED_AREA_TYPES, lowerType)) || (upper && !_.includes(SHADED_AREA_TYPES, upperType))) {
    throw new Error('Only signals and scalars can be used as bounds for shaded areas');
  }

  // If both items are scalars we don't need to fetch the bounds as the display range changes, so they are
  // stored in the scalars store and they are fetched only once.
  const itemType =
    lowerType === ITEM_TYPES.SCALAR && upperType === ITEM_TYPES.SCALAR ? ITEM_TYPES.SCALAR : ITEM_TYPES.SERIES;

  if (lower && upper) {
    // If the base item is one of the thresholds, assume that it is the focus of the shading and only show that
    // cursor
    let shadedAreaCursors = SHADED_AREA_CURSORS.BOTH;
    if (baseItem.id === lower.id) {
      shadedAreaCursors = SHADED_AREA_CURSORS.LOWER;
    }
    if (baseItem.id === upper.id) {
      shadedAreaCursors = SHADED_AREA_CURSORS.UPPER;
    }
    if (baseItem.isNeutral) {
      shadedAreaCursors = SHADED_AREA_CURSORS.NONE;
    }

    addChildItem(
      parentId,
      childType,
      { id, name, type, itemType },
      {
        shadedAreaLower: {
          id: lower.id,
          isSignal: lowerType === ITEM_TYPES.SERIES,
        },
        shadedAreaUpper: {
          id: upper.id,
          isSignal: upperType === ITEM_TYPES.SERIES,
        },
        shadedAreaCursors,
        ...props,
      },
      option,
      skipDependencies,
    );
  } else if (lower || upper) {
    const { id: interestId, type, itemType } = !upper ? lower : upper;
    addChildItem(
      parentId,
      childType,
      { id, name, type, itemType },
      {
        interestId,
        shadedAreaDirection: !upper ? SHADED_AREA_DIRECTION.UP : SHADED_AREA_DIRECTION.DOWN,
        ...props,
      },
      option,
      skipDependencies,
    );
  } else {
    throw new Error('Only one bound can be omitted');
  }
}

/**
 * Adds a child item to the trend stores
 *
 * @param parentId - id of the parent which is used for `isChildOf`
 * @param childType - one of ITEM_CHILDREN_TYPES
 * @param {Object} item - item to add to the trend
 * @param [props] - extra properties to set on the item
 * @param [option] - argument to pass to relevant flux.dispatch calls
 * @param skipDependencies - Will signal whether to skip the child's dependencies
 * @returns {Promise} that resolves when the item has been added
 */
export function addChildItem(
  parentId: string,
  childType: string,
  item,
  props?: object,
  option?: string,
  skipDependencies = false,
): Promise<any> {
  const parent = findItemIn(getTrendStores(), parentId);
  const { id: interestId, name, type, itemType } = item;
  // The concatenation of the ids is used only so that the child item's id won't conflict with the ids of other
  // items. Any unique id would work, but the concatenation is useful for debugging and predictability.
  const id = _.get(props, 'id', `${parentId}_${interestId}`);
  return exposedForTesting.addTrendItem(
    { id, name: parent.name, type, itemType },
    {
      childType,
      isChildOf: parentId,
      interestId, // Placed before ...props so that the caller can override the interestId
      ..._.omit(props, ['id']),
      ..._.pick(parent, CHILD_CLONED_PROPERTIES[childType]),
    },
    option,
    skipDependencies,
  );
}

/**
 * Attempt to translate an item that may be from the backend (has a type parameter) or from the frontend (has an
 * itemType parameter) into the item types that the frontend uses to identify items. Otherwise falls back to the
 * item.type.
 *
 * @param {Object} item - item to get the type of
 * @returns {string} the type of the item
 */
export function getItemType(item) {
  return item.itemType || API_TYPES_TO_ITEM_TYPES[item.type];
}

/**
 * Adds child metric items for a metric. These are the series and thresholds that are displayed on the trend
 *
 * @param itemId
 * @param fetchedMetric
 * @param skipDependencies will skip unneeded dependencies. Useful if these have already been fetched
 */
export function addMetricChildren(itemId: string, fetchedMetric: ThresholdMetricOutputV1, skipDependencies = false) {
  // Set metric first so definition is available when children are added
  const props = _.omitBy(
    {
      definition: fetchedMetric,
      valueUnitOfMeasure: fetchedMetric.valueUnitOfMeasure,
    },
    _.isUndefined,
  );
  exposedForTesting.setTrendItemProps(itemId, props, PUSH_IGNORE);

  // Remove existing children of this item before updated ones are re-added
  exposedForTesting.removeChildren(itemId);

  // Make the item type be that which will be returned when the formula function is executed and set the fragments
  // property to be an object where the key is the parameter name and the value is the formula fragment type that
  // should be used when the signal is fetched (in this case a capsule formula for the current display range).
  const displayItemProps = {} as any;
  const displayItem = _.cloneDeep(fetchedMetric.displayItem) as any;
  if (displayItem.type === API_TYPES.FORMULA_FUNCTION) {
    displayItem.itemType = ITEM_TYPES.SERIES;
    displayItemProps.fragments = {
      capsule: FORMULA_FRAGMENT_TYPE.DISPLAY_RANGE,
    };
  }
  if (displayItem.type === API_TYPES.CALCULATED_SIGNAL) {
    // Prevents a brief switch to linear interpolation in-between when this item is created and when the data comes
    // back (which will have the interpolation method). This is safe to set to Step because the metric always
    // creates an aggregated signal with Step interpolation, except for `totalized`, but that stat has a small flicker
    // even when forced to Linear.
    displayItemProps.interpolationMethod = 'Step';
  }

  addChildItem(
    itemId,
    ITEM_CHILDREN_TYPES.METRIC_DISPLAY,
    displayItem,
    displayItemProps,
    PUSH_IGNORE,
    skipDependencies,
  );

  // Ordering by priority makes things easier to reason about here
  const thresholds = _.chain(fetchedMetric.thresholds)
    .cloneDeep()
    .concat({
      item: displayItem,
      priority: { level: 0, color: fetchedMetric.neutralColor },
    } as ThresholdOutputV1)
    .sortBy('priority.level')
    .value();
  if (
    fetchedMetric.valueUnitOfMeasure !== 'string' &&
    _.every(thresholds, (threshold) => _.includes(SHADED_AREA_TYPES, getItemType(threshold.item)))
  ) {
    // Display thresholds as shaded areas on the trend
    _.forEach(thresholds, (threshold, i) => {
      const baseParams = {
        parentId: itemId,
        childType: ITEM_CHILDREN_TYPES.METRIC_THRESHOLD,
        baseItem: threshold.item,
        props: {
          // Include the priority level so the same signal used for multiple thresholds doesn't cause unexpected
          // results
          id: `${itemId}_${threshold.item.id}_${threshold.priority.level}`,
          dashStyle: DASH_STYLES.SOLID,
          color: threshold.priority.color,
          axisVisibility: false,
          fillOpacity: 0.09,
          valueUnitOfMeasure: threshold?.value?.uom ?? '',
        },
      };
      const maybeUpperItem = _.get(thresholds, [i + 1, 'item']);
      const maybeLowerItem = _.get(thresholds, [i - 1, 'item']);
      if (threshold.priority.level === 0) {
        if ((maybeLowerItem || maybeUpperItem) && threshold.priority.color !== '#ffffff') {
          addShadedArea(
            {
              ...baseParams,
              baseItem: { ...threshold.item, isNeutral: true },
              lower: maybeLowerItem,
              upper: maybeUpperItem,
            },
            PUSH_IGNORE,
            skipDependencies,
          );
        }
      } else if (threshold.priority.level > 0) {
        addShadedArea(
          {
            ...baseParams,
            lower: threshold.item,
            upper: maybeUpperItem,
          },
          PUSH_IGNORE,
          skipDependencies,
        );
      } else {
        addShadedArea(
          {
            ...baseParams,
            lower: maybeLowerItem,
            upper: threshold.item,
          },
          PUSH_IGNORE,
          skipDependencies,
        );
      }
    });
  } else {
    // Display thresholds as dotted lines on the trend
    _.chain(thresholds)
      .reject((threshold) => threshold.priority.level === 0)
      .forEach((threshold, i) => {
        const props = {
          // Include the priority level so the same signal used for multiple thresholds doesn't cause unexpected
          // results
          id: `${itemId}_${threshold.item.id}_${threshold.priority.level}`,
          dashStyle: DASH_STYLES.DASH,
          color: threshold.priority.color,
          axisVisibility: false,
        };
        addChildItem(
          itemId,
          ITEM_CHILDREN_TYPES.METRIC_THRESHOLD,
          threshold.item,
          props,
          PUSH_IGNORE,
          skipDependencies,
        );
      })
      .value();
  }
}

/**
 * Aligns a metric's measured item to be on the same lane, the same axis if it shares the same UOM, and selection
 * status if metric is selected.
 *
 * @param {Object} metric - The metric with the measured item
 */
export function alignMeasuredItemWithMetric(metric) {
  const measuredItem = findItemIn(getTrendStores(), _.get(metric, 'definition.measuredItem.id'));
  if (!measuredItem) {
    return;
  }

  const customizationProps = _.omitBy(
    {
      lane: measuredItem.itemType !== ITEM_TYPES.CONDITION ? metric.lane : undefined,
      axisAlign: metric.valueUnitOfMeasure === measuredItem.valueUnitOfMeasure ? metric.axisAlign : undefined,
    },
    _.isNil,
  );
  exposedForTesting.setCustomizationProps([
    {
      id: measuredItem.id,
      ...customizationProps,
    },
  ]);
  updateLaneDisplay();

  if (metric.selected) {
    exposedForTesting.setItemSelected(measuredItem, true);
  }
}

/**
 * Fetches properties for all items.
 *
 * @param skipDependencies allows property dependencies to be skipped. Useful if these items
 * already have been fetched or are not needed.
 * @returns Resolves when all properties are fetched.
 */
export async function fetchPropsForAllItems(skipDependencies = false) {
  const itemIds = getAllItems({}).map((item) => item.id);
  const inputObject: GraphQLInputV1 = {
    query: GET_CONTEXT_CONDITIONS_QUERY,
    variables: {
      ids: itemIds.map((id) => ({ itemId: id })),
    },
  };
  const contextConditions: ContextConditionsMap | undefined = (
    await sqGraphQLApi.graphql(inputObject)
  ).data.data?.context?.conditions?.reduce(
    (memo, response) => ({ ...memo, [response.sourceItemId]: response.guid }),
    {} as ContextConditionsMap,
  );
  return Promise.all(
    itemIds.map((id) => exposedForTesting.fetchItemProps(id, skipDependencies, { contextConditions })),
  );
}

/**
 * Helper function to be used in conjunction with fetching data for an item. It sets the item status to "Canceled"
 * or "Failure" based on the response. Because the item status should already be set (e.g. to "Loading"), we only
 * set the cancelled item status if the request was canceled and there is a not new request already in flight and
 * the cancellation call was not flagged as "refetching" which indicates that a data request will follow the
 * cancellation request. The refetching check is needed to prevent unwanted red triangles in the details pane that
 * could occur due to a race condition when the user moved the trend around quickly (causing many cancellations) on
 * a slow network. The race condition was that a prior cancellation promise could resolve after a call to cancel a
 * cancellation group but before the pending requests cancellation interceptor had added the next request (which
 * would result in a no pending requests existing for the cancellation group at that particular moment).
 *
 * @param {String} id - The id of the item
 * @param {String} cancellationGroup - the group name used to count the number of requests
 * @param {Object} error - The error object from the API request
 * @param {any} defaultValue? - An optional return value to be used if specified
 * @returns {Promise|any} returns defaultValue if provided, or otherwise, a rejected promise if a failure status
 * was set on the item
 */
export function catchItemDataFailure(id, cancellationGroup, error, defaultValue?) {
  const nameWithId = `"${_.get(findItemIn(getTrendStores(), id), 'name', '')}" (${id})`;
  const canceled = isCanceled(error);

  // Show a cancellation "red triangle" in the details pane since this cancellation wasn't expected
  if (canceled && !count(cancellationGroup) && !_.get(error, 'config.refetching', false)) {
    if (_.includes(_.get(error, 'data.statusMessage', ''), SeeqNames.API.ErrorMessages.CancelledDueToTooManyRetries)) {
      flux.dispatch('TREND_SET_DATA_STATUS_CANCELED_RETRIES', { id }, PUSH_IGNORE);
    } else {
      flux.dispatch('TREND_SET_DATA_STATUS_CANCELED', { id }, PUSH_IGNORE);
      // Reset statistics when the request was canceled
      flux.dispatch('TREND_SET_STATISTICS', { id, statistics: {} }, PUSH_IGNORE);
    }
    notifyCancellation(`Item data request canceled for ${nameWithId}`);
    // logInfo(`Item data request canceled for ${nameWithId}`);
  } else if (canceled && error?.xhrStatus === 'abort' && error?.config?.refetching) {
    // CRAB-22078: if an item is refetching, sometimes we get stuck in state with an infinite spinner,
    // so tell the user there was a failure
    flux.dispatch('TREND_SET_DATA_STATUS_FAILURE', { id }, PUSH_IGNORE);
    logWarn(`Item data failure for ${nameWithId}`);
  }

  // Show a "red triangle" in the details pane with more information
  if (!canceled) {
    const message = _.get(error, 'data.statusMessage');
    const errorType = _.get(error, 'data.errorType');
    const errorCategory = _.get(error, 'data.errorCategory');
    const inaccessible = _.get(error, 'data.inaccessible');
    if (errorType === ErrorTypeEnum.MAXDURATIONREQUIRED || errorType === ErrorTypeEnum.MAXDURATIONPROHIBITED) {
      doTrack('ERROR', errorType, message);
    }
    if (isForbidden(error)) {
      handleForbidden(error);
      if (_.isNil(inaccessible) || _.includes(inaccessible, id)) {
        flux.dispatch('TREND_SET_DATA_STATUS_REDACTED', { id, message, errorType, errorCategory }, PUSH_IGNORE);
        logWarn(`Item redacted ${nameWithId}: ${message}`);
      } else {
        flux.dispatch('TREND_SET_DATA_STATUS_ABORTED', { id, message, errorType, errorCategory }, PUSH_IGNORE);
        logWarn(`Item data aborted for ${nameWithId}: ${message}`);
      }
    } else {
      flux.dispatch('TREND_SET_DATA_STATUS_FAILURE', { id, message, errorType, errorCategory }, PUSH_IGNORE);
      logWarn(`Item data failure for ${nameWithId}: ${message}`);
    }

    if (_.isNil(defaultValue)) {
      return Promise.reject(error);
    }
  }

  if (!_.isNil(defaultValue)) {
    return defaultValue;
  }
}

/**
 * Adds, updates, or removes a custom label. Undefined or empty text will cause the label to be removed
 *
 * @param location - the location of the target (lane or axis) one of LABEL_LOCATIONS
 * @param target - the name of the axis or lane
 * @param text - the text to set the custom label or undefined or empty text to remove label
 */
export function setCustomLabel<LabelLocation extends 'lane' | 'axis'>(
  location: LabelLocation,
  target: LabelLocation extends 'lane' ? number : string,
  text: string,
) {
  if (_.isEmpty(_.trim(text))) {
    flux.dispatch('TREND_REMOVE_CUSTOM_LABEL', {
      location,
      target,
    });
  } else {
    flux.dispatch('TREND_SET_CUSTOM_LABEL', {
      location,
      target,
      text,
    });
  }
}

/**
 * Toggles whether or not capsule previews are loaded and displayed in the timebar
 * Fetches or clears the timebar capsules depending on the current toggle position
 *
 * @param {boolean} capsulePreview - whether or not the capsules should be previewed on investigate range
 */
export function setCapsulePreview(capsulePreview) {
  flux.dispatch('TREND_SET_CAPSULE_PREVIEW', { capsulePreview });

  _.forEach(sqTrendConditionStore.items, (item) => exposedForTesting.fetchTimebarCapsules(item.id));
}

/**
 * Sets column filter
 *
 * @param key - column key of the column being filtered
 * @param filter - filter to be applied to the column
 * */
export function setColumnFilter(key: string, filter: TableColumnFilter | undefined) {
  const currentColumnFilter = sqTrendStore.getColumnFilter(key);
  if ((!filter && !currentColumnFilter) || _.isEqual(currentColumnFilter, filter)) {
    return;
  }

  flux.dispatch('TREND_SET_COLUMN_FILTER', { key, filter });
  exposedForTesting.resetCapsulePanelOffset();
  exposedForTesting.fetchTableAndChartCapsules();
  exposedForTesting.fetchChartCapsules();
  fetchPlot();
}

/**
 * Removes filter from columnFilters by setting it to undefined.
 *
 * @param key - column key for which column to remove the filter
 * @param panel - The panel the column is in
 * @throws {Error} if `panel` is equal to `TREND_PANELS.CHART_CAPSULES`
 * */
export function removeColumnFilter(key: string, panel: TREND_PANELS) {
  if (panel === TREND_PANELS.CHART_CAPSULES) {
    throw new Error(`Removing filters is not supported for ${TREND_PANELS.CHART_CAPSULES}`);
  }

  exposedForTesting.setColumnFilter(key, undefined);
}

/**
 * Sets the format string for a panel column.
 *
 * @param key - The column key of the column to format
 * @param format - The format string
 * @param panel - The panel the column is in
 * */
export function setColumnFormat(key: string, format: string, panel: TREND_PANELS) {
  flux.dispatch('TREND_SET_COLUMN_FORMAT', { key, format, panel });
  if (panel === TREND_PANELS.CAPSULES) {
    flux.dispatch('TREND_RECOMPUTE_CHART_CAPSULES', {}, PUSH_IGNORE);
  }
}

/**
 * Removes the children of an item
 *
 * @param {String} itemId - the item ID
 */
export function removeChildren(itemId) {
  exposedForTesting.removeItems(findChildrenIn(getTrendStores(), itemId));
}

/**
 * Helper that gets the Item ID for an item. Accounts for the fact that frontend items can have an "id" property
 * that is not the same as the GUID used by the backend.
 *
 * @param {Object} item - An item from one of the trend stores
 * @return {string} The GUID to use for API calls
 */
export function getItemId(item) {
  return item.childType && item.interestId ? item.interestId : item.id;
}

/**
 * Returns a comma separated list of the currently selected stats - ready for spikeCatcher formula input
 */
export function getEnabledColumns() {
  const stats = _.chain(TREND_SIGNAL_STATS)
    .filter((stat: any) => sqTrendStore.isColumnEnabled(TREND_PANELS.SERIES, stat.key))
    .map((stat: any) => stat.stat)
    .value();

  return _.get(stats, 'length') ? `, ${_.join(stats, ', ')}` : '';
}

/**
 * @example
 *          ---If you need debounced version of a function, put handle up top like:
 *          let debouncedHandle;
 *
 *          ---then, when you need to use it somewhere:
 *          function foo() {
 *            ...
 *            debouncedHandle =  lazyDebounceOf(debouncedFunction, debouncedHandle);
 *            debouncedHandle();
 *          }
 * @param debounceHandle
 * @param functionToDebounce
 */
export function lazyDebounceOf(functionToDebounce, debounceHandle) {
  // We defer calling _.debounce until the first time it is used so that protractor running the system
  // tests has an opportunity to replace _.debounce in protractor.conf. See CRAB-7098
  if (!debounceHandle) {
    debounceHandle = _.debounce(functionToDebounce, DEBOUNCE.MEDIUM);
  }
  return debounceHandle;
}

/**
 * Sets the list of property options to separate and/or color by in compare mode
 *
 * @param {ReactSelectOption<string>[]} capsuleProperties - list of property options
 */
export function setCapsuleProperties(capsuleProperties: ReactSelectOption<string>[]) {
  flux.dispatch('TREND_SET_CAPSULE_PROPERTIES', { capsuleProperties });
}

/**
 * Sets the property to color by in compare mode
 *
 * @param {String} colorByProperty - property to use for coloring in compare mode
 */
export function setColorByProperty(colorByProperty: string) {
  flux.dispatch('TREND_SET_COLOR_BY_PROPERTY', { colorByProperty });
}

/**
 * Sets the property to separate by in compare mode
 *
 * @param {String} separateByProperty - property to use for separation in compare mode
 */
export function setSeparateByProperty(separateByProperty: string) {
  flux.dispatch('TREND_SET_SEPARATE_BY_PROPERTY', { separateByProperty });
}

/**
 * Sets the first column in compare mode
 *
 * @param {String} payload.firstColumn - first column
 */
export function setFirstColumn(firstColumn: string) {
  flux.dispatch('TREND_SET_FIRST_COLUMN', { firstColumn });
}

export function setShowSelectedRegionOptions(show: boolean) {
  flux.dispatch('TREND_SET_SHOW_SELECTED_REGION_OPTIONS', {
    showSelectedRegionOptions: show,
  });
}

function clearStatisticsForUnselectedItems(items: { selected?: boolean; id: string }[]) {
  items
    .filter((item) => !item.selected)
    .forEach((item) => flux.dispatch('TREND_SET_STATISTICS', { id: item.id, statistics: {} }, PUSH_IGNORE));
}

export const exposedForTesting = {
  GET_CONTEXT_CONDITIONS_QUERY,
  getChartWidth,
  setCustomizationMode,
  fetchItemProps,
  removeColumnFilter,
  setCapsuleTimeColorMode,
  setItemSelected,
  fetchTimeseries,
  fetchTableAndChartCapsules,
  setColumnEnabled,
  fetchTimebarCapsules,
  fetchChartCapsules,
  fetchStatistics,
  setTrendItemProps,
  addTrendItem,
  fetchScalar,
  removeUnselectedSeriesFromCapsules,
  fetchHiddenTrendData,
  fetchItems,
  fetchDataBasedOnView,
  catchItemDataFailure,
  addMetricChildren,
  addCapsuleTimeSegments,
  removeChildren,
  setCustomizationProps,
  setShowSelectedRegionOptions,
  removeItems,
  fetchAllTimebarCapsules,
  removeAllItems,
  removeTrendSelectedRegion,
  createStitchDetails,
  fetchAllTimeseries,
  setTrendCapsuleTimeOffsets,
  fetchSignalSegments,
  updateStatistics,
  fetchAllStatistics,
  setTrendSelectedRegion,
  setColumnFilter,
  removePropertiesColumn,
  setTrendItemColor,
  fetchAllScalars,
  fetchPreviewSeries,
  fetchPropsForAllItems,
  setCapsulePanelOffset,
  setPanelSort,
  fetchAllItems,
  displayEmptyPreviewCapsuleLane,
  generatePreviewSeries,
  toggleTrendItemSelected,
  unselectAllCapsules,
  removeTrendItem,
  setTrendView,
  setGridlines,
  toggleHideUnselectedItems,
  toggleDimDataOutsideCapsules,
  setLabelDisplayConfiguration,
  getCapsuleCustomPropertyValuesAndUOMs,
  getItemCustomPropertyValuesAndUOMs,
  resetCapsulePanelOffset,
  updateCustomLabelTargets,
};
